import React, { FC, useEffect } from 'react';
import s from './Mentors.module.css'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByMentorsList } from '../../store/slices/coursesSlice';
import { useTranslation } from 'react-i18next';
import MentorCard from './MentorCard/MentorCard';

interface MentorsProps {
    id: number
}

const Mentors: FC<MentorsProps> = ({ id }) => {
    const { t } = useTranslation()
    const { mentors } = useAppSelector(state => state.courses)
    const dispatch = useAppDispatch()

    // console.log(mentors)

    useEffect(() => {
        dispatch(fetchByMentorsList(id))
    }, [id, dispatch])
    return (
        <>
            {
                mentors.length > 0 &&
                <div className={s.container}>
                    <div className={s.titles_block}>
                        <h2 className={s.title}>{t("mentors.title")}</h2>
                        <h4 className={s.under_title}>{t("mentors.under_title")}</h4>
                        <p className={s.mentors_info}>{t("mentors.mentors_info")}</p>
                    </div>
                    <div className={s.mentors_block}>
                        {
                            mentors?.map(el => <MentorCard key={el.id} item={el} />)
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default Mentors;