import React, { FC } from 'react'
import s from '../Technologies.module.css'
import { TechnologiesData } from '../../../../../store/modules'
import { useTranslation } from 'react-i18next';

interface TechnologiesIconProps {
    technology: TechnologiesData
}

const TechnologiesIcon: FC<TechnologiesIconProps> = ({ technology }) => {
    const { i18n } = useTranslation()
    const lang = i18n.language
    const { logo, name_en, name_ru } = technology

    return (
        <div className={s.icon_block}>
            <img className={s.icon} src={`https${logo.slice(4)}`} alt={lang === 'ru' ? name_ru : name_en} />
        </div>
    )
}

export default TechnologiesIcon