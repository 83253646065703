import React, { FC, useEffect } from 'react';
import s from './AllMentors.module.css'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchByMentorsList } from '../../../store/slices/coursesSlice';
import MentorCard from '../../../components/Mentors/MentorCard/MentorCard';

const AllMentors: FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { mentors } = useAppSelector(state => state.courses)

    useEffect(() => {
        dispatch(fetchByMentorsList(''))
    }, [dispatch])
    return (
        <div className={s.container}>
            <div className={s.title_block}>
                <h5 className={s.section_title}>{t("all_mentors.top_title")}</h5>
                <h2 className={s.title_description}>{t("all_mentors.title")}</h2>
                <p className={s.mentors_info}>{t("mentors.mentors_info")}</p>
            </div>
            <div className={s.all_mentors_block}>
                {
                    mentors.length > 0 &&
                    mentors.map(el => <MentorCard key={el.id} item={el} />)
                }
            </div>
        </div>
    );
};

export default AllMentors;