import { Dispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { platformAPI } from '../../axios'
import { FavoritesData, ForFetchUser, ListVideosUserCoursesData, UserCoursesData, VideoData } from '../modules'
import { AppDispatch } from '..'

type InfoState = {
    list: UserCoursesData[]
    list_videos: ListVideosUserCoursesData | null
    video: VideoData | null
    whishlist: FavoritesData[]
    loading: boolean
    error: string | null
    btn_disabled: boolean
}

const initialState: InfoState = {
    list: [],
    list_videos: null,
    video: null,
    whishlist: [],
    loading: false,
    error: null,
    btn_disabled: false,
}

export const fetchByUserCourses = createAsyncThunk<UserCoursesData[], string, { rejectValue: string }>(
    'user/fetchByUserCourses',
    async (token, { rejectWithValue }) => {
        const res = await platformAPI.getListUserCourses(token)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Server error')
        }

        return res.data
    }
)

export const fetchByListVideosByTechnologie = createAsyncThunk<ListVideosUserCoursesData, { token: string, id_technologie: string }, { rejectValue: string }>(
    'user/fetchByListVideosByTechnologie',
    async (data, { rejectWithValue }) => {
        const res = await platformAPI.getListVideosUserCourses(data)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Server error')
        }

        return res.data
    }
)

export const fetchVideoById = createAsyncThunk<VideoData, ForFetchUser, { rejectValue: string }>(
    'user/fetchVideoById',
    async (data, { rejectWithValue }) => {
        const res = await platformAPI.getVideo(data)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Server error')
        }

        return res.data
    }
)

export const fetchToggleUserFavorites = createAsyncThunk<void, ForFetchUser, { rejectValue: string, dispatch: AppDispatch }>(
    'user/fetchToggleUserFavorites',
    async (data, { rejectWithValue, dispatch }) => {
        const res = await platformAPI.toggleVideoInWhishlist(data)

        if (res.status < 200 && res.status > 201) {
            return rejectWithValue('Server error')
        }

        dispatch(fetchByGetListFavorites(data.token))
    }
)

export const fetchByGetListFavorites = createAsyncThunk<FavoritesData[], string, { rejectValue: string }>(
    'user/fetchByGetListFavorites',
    async (token, { rejectWithValue }) => {
        const res = await platformAPI.getListFavorites(token)

        if (res.status !== 200) {
            return rejectWithValue('Server error')
        }

        return res.data
    }
)


const userCoursesSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {
        clearListUserCourses(state) {
            state.list = []
        }
    },
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchByUserCourses.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByUserCourses.fulfilled, (state, action) => {
            state.list = action.payload
            state.loading = false
        })
        addCase(fetchByListVideosByTechnologie.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByListVideosByTechnologie.fulfilled, (state, action) => {
            state.list_videos = action.payload
            state.loading = false
        })
        addCase(fetchVideoById.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchVideoById.fulfilled, (state, action) => {
            state.video = action.payload
            state.loading = false
        })
        addCase(fetchToggleUserFavorites.pending, (state) => {
            state.btn_disabled = true
        })
        addCase(fetchToggleUserFavorites.fulfilled, (state) => {
            state.btn_disabled = false
        })
        addCase(fetchByGetListFavorites.pending, (state) => {
            state.loading = true
            state.error = null
        })
        addCase(fetchByGetListFavorites.fulfilled, (state, action) => {
            state.whishlist = action.payload
            state.loading = false
        })
    }
})

export const { clearListUserCourses } = userCoursesSlice.actions

export default userCoursesSlice.reducer

