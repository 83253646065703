import React, { FC, useState } from 'react';
import s from './Header.module.css'
import Navigation from '../Navigation/Navigation';
import { Link } from 'react-router-dom';
import AuthBtns from '../AuthBtns/AuthBtns';
import { useAppSelector } from '../../store/hooks/hooks';
import ProfileBlock from '../ProfileBlock/ProfileBlock';
import { pathLink } from '../../reused';
import ChangePassModal from '../AuthScenes/ChangePassModal/ChangePassModal';
import BurgerMenu from '../Navigation/BurgerMenu/BurgerMenu';

const Header: FC = () => {
    const { token, change_pass_modal } = useAppSelector(state => state.user)
    const { company } = useAppSelector(state => state?.info)
    const [showSettings, setShowSettings] = useState(false)

    return (
        <header>
            <div className={`${s.container} ${s.header}`}>
                <Link onClick={() => setShowSettings(false)} to={'/'} className={s.logo_block}>
                    <img className={s.logo} title={company?.name} src={pathLink + company?.logo} alt='logo' />
                </Link>
                <Navigation setShowSettings={setShowSettings} showSettings={showSettings} />
                <BurgerMenu setShowSettings={setShowSettings} showSettings={showSettings} />
                {token ? <ProfileBlock setShowSettings={setShowSettings} showSettings={showSettings} /> : <AuthBtns />}
                {change_pass_modal && <ChangePassModal />}
            </div>
        </header>
    );
};

export default Header;