import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import s from './NotFound.module.css'
import Error404 from '../../assets/images/Error404.png'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const NotFound: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/', { replace: true })
        }, 3500)

        return () => clearTimeout(timer)
    }, [navigate])
    return (
        <div className={s.notFound_section}>
            <Helmet>
                <title>
                    404 Not Found!!! | ITCbootcamp
                </title>
            </Helmet>
            <div className={s.container}>
                <img className={s.errorImg} src={Error404} alt="PageNotFound" />
                <h2 className={s.notFound_title}>
                    {t("notFound.pageNotFound")}
                </h2>
                <p className={s.apology}>
                    {t("notFound.apology")}
                </p>
                <button className={s.toMainPage_btn}>
                    {t("notFound.toMainPage")}
                </button>
            </div>

        </div>
    );
};

export default NotFound;