import axios from "axios";
import { ChangePassData, ForFetchUser, NewBookingCourseData, NewCallBackData, NewPassData, NewReviewData, NewUserData, TokenData, UserData, VerifyData, PaymentData } from "../store/modules";


const instanse = axios.create({
    baseURL: 'https://itcbootcamp.com:4443/',
    headers: {
        'Content-Type': 'application/json',
    }
})

export const platformAPI = {
    addNewUser({ user_email, user_name, user_pass, user_pass_repeat }: UserData) {
        return instanse.post('api/users/registration/', {
            "username": user_name,
            "password": user_pass,
            "password_repeat": user_pass_repeat,
            "email": user_email
        }).then(res => res)
    },
    verificate(data: VerifyData) {
        return instanse.post('api/users/verification/', data)
    },
    newVerifyCode(user_id: number) {
        return instanse.post('api/users/new_code/', { user_id })
    },
    loginToken({ username, password }: TokenData) {
        return instanse.post('api/users/token/', { username, password })
    },
    getUser(token: string) {
        const header = { 'Authorization': `Bearer ${token}` }
        return instanse.get('api/users/profile/', { headers: header })
    },
    getCompanyInfo() {
        return instanse.get('api/company/company_info/')
    },
    getAllCourses() {
        return instanse.get('api/cources/cources/')
    },
    getCourseInfo(id: number) {
        return instanse.get(`api/cources/cources/${id}/`)
    },
    getResumeByCourse(id: number) {
        return instanse.get(`api/cources/resume/?cource__id=${id}`)
    },
    getTechnologie(id: number) {
        return instanse.get(`api/cources/technologies/${id}/`)
    },
    updateUserInfo({ avatar, first_name, id, last_name, phone_number, token }: NewUserData) {
        const header = { 'Authorization': `Bearer ${token}`, "Content-Type": "multipart/form-data" }
        return instanse.patch(`api/users/change_profile/${id}/`, { first_name, last_name, phone_number, avatar }, { headers: header })
    },
    changePass(data: ChangePassData) {
        return instanse.post('api/users/password_reset/', data)
    },
    addNewPass({ linkToken, password }: NewPassData) {
        return instanse.post(`api/users/reset_password/?token=${linkToken}`, { password })
    },
    deleteUserAvatar(token: string) {
        const header = { 'Authorization': `Bearer ${token}` }
        return instanse.get('api/users/delete_user_avatar/', { headers: header })
    },
    getReviews() {
        return instanse.get('api/company/reviews/')
    },
    addNewReview({ token, comment }: NewReviewData) {
        const header = { 'Authorization': `Bearer ${token}` }
        return instanse.post('api/company/reviews/', { comment }, { headers: header })
    },
    addNewCallBack(data: NewCallBackData) {
        return instanse.post('api/company/feedbacks/', data)
    },
    getStudentWorks() {
        return instanse.get('api/cources/student_works/')
    },
    getCoopCompanies() {
        return instanse.get('api/company/cooperate_companies/')
    },
    getMentorsList(id: number | string = '') {
        return instanse.get(`api/cources/mentors/?cource__id=${id}`)
    },
    getFAQ(id: number) {
        return instanse.get(`api/cources/question_answers/?cource__id=${id}`)
    },
    getCompanyAQ() {
        return instanse.get('api/company/company_question_answers/')
    },
    getBookingCourses({ id, city }: { id: number, city: string }) {
        return instanse.get(`api/cources/booking_cource/?cource__id=${id}&city=${city}`)
    },
    addNewBookingCourse({ token, id, city }: NewBookingCourseData) {
        const header = { 'Authorization': `Bearer ${token}` }
        return instanse.post(`api/cources/user_booking_cource/`, { booking_cource: id, city }, { headers: header })
    },
    getListUserCourses(token: string) {
        const header = { 'Authorization': `Bearer ${token}` }
        return instanse.get('api/users/my_cource/', { headers: header })
    },
    getListVideosUserCourses({ token, id_technologie }: { token: string, id_technologie: string }) {
        const header = { 'Authorization': `Bearer ${token}` }
        return instanse.get(`api/users/my_cource/${id_technologie}/`, { headers: header })
    },
    getVideo({ token, video_id }: ForFetchUser) {
        const header = { 'Authorization': `Bearer ${token}` }
        return instanse.get(`api/cources/video/${video_id}/`, { headers: header })
    },
    toggleVideoInWhishlist({ token, video_id }: ForFetchUser) {
        const header = { 'Authorization': `Bearer ${token}` }
        return instanse.post(`api/cources/faforite_video/`, { video: video_id }, { headers: header })
    },
    getListFavorites(token: string) {
        const header = { 'Authorization': `Bearer ${token}` }
        return instanse.get(`api/cources/faforite_video/`, { headers: header })
    },
    getPaymentLink(data: PaymentData) {
        return instanse.post(`api/cources/get_payment_url/`, data)
    },
    addNewTechnologyByUser({ token, id_technologie }: { token: string, id_technologie: string }) {
        const header = { 'Authorization': `Bearer ${token}` }
        return instanse.post(`api/cources/user_technology/`, { technology: id_technologie }, { headers: header })
    },
    getPyamentLinkByTechnology(data: PaymentData) {
        return instanse.post('api/cources/get_payment_url_technology/', data)
    },
    addNewFullCourseByUser({ token, id }: { token: string, id: string }) {
        const header = { 'Authorization': `Bearer ${token}` }
        return instanse.post(`api/cources/user_course/`, { cource: id }, { headers: header })
    },
    getPyamentLinkByFullCourse(data: PaymentData) {
        return instanse.post('api/cources/get_payment_url_course/', data)
    }
}

// console.log();
