import React, { FC } from 'react'
import s from './Hero.module.css'
import ProgrammerImg from '../../../../assets/images/HomePage/Logos.png'
// import playIcon from '../../../../assets/images/HomePage/play_icon.png'
import { t } from 'i18next'
import Advantages from '../Advantages/Advantages'
import { Link } from "react-scroll";

const Hero: FC = () => {
    return (
        <section className={`${s.hero_section} animate__animated animate__faster animate__zoomIn`}>
            <div className={s.dark_bg} />

            <div className={s.container}>
                <div className={`${s.leftSide} ${s.block}`}>

                    <h1 className={s.main_title}>{t("home.main.title")}</h1>
                    <p className={s.title_description}>{t("home.main.title-description")}</p>
                    <div className={s.main_section_buttons}>
                        <Link
                            spy={false}
                            smooth={true}
                            offset={-70}
                            // duration={1500}
                            to={'leave_req'}
                            className={s.leaveAReq_btn}
                        >
                            {t("home.main.leaveARequest")}
                        </Link>
                        {/* <button className={s.whatchAPresentation}>
                            <img src={playIcon} alt="play" />
                            {t("home.main.watchThePresentation")}
                        </button> */}
                    </div>
                </div>

                <div className={`${s.rightSide} ${s.block}`}>
                    <img className={s.hero_icon} src={ProgrammerImg} alt="Programmer" />
                </div>
            </div>

            <Advantages />

        </section>
    )
}

export default Hero