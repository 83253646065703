import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { platformAPI } from "../../axios"
import { removeLS, setLS } from "../../LS"
import { ChangePassData, NewPassData, NewUserData, TokenData, UserData, UserProfileData, UserUpdatedData, VerifyData } from "../modules"

type NewCodeData = {
    new_code: string
    user_id: string
}

type ResUserData = {
    message?: string
    user_id: number | null
}

type UserState = ResUserData & {
    user_email: string
    user_name: string
    user_phone: string
    user_pass: string
    user_pass_repeat: string
    login_modal: boolean
    regist_modal: boolean
    confirm_logout_modal: boolean
    account_modal: boolean
    verify_modal: boolean
    change_pass_modal: boolean
    success_change_pass: string
    redirect_after_change: boolean
    token: string
    error_message: boolean
    user_profile: UserProfileData
}

export const addNewUser = createAsyncThunk<ResUserData, UserData, { rejectValue: string }>(
    'user/addNewUser',
    async (obj, { rejectWithValue }) => {
        try {
            const res = await platformAPI.addNewUser(obj)
            // console.log(res);

            if (res.status !== 201) {
                throw new Error('Error')
            }

            return res.data
        } catch (error: any) {
            // console.log(error.response.data.Error);
            return rejectWithValue(error.response.data.Error)
        }

    }
)

export const verifyUser = createAsyncThunk<string, VerifyData, { rejectValue: string }>(
    'user/verifyUser',
    async (data, { rejectWithValue }) => {
        const res = await platformAPI.verificate(data)
        // console.log(res);

        if (res.status !== 201) {
            return rejectWithValue('Error')
        }

        return res.data.Message
    }
)

export const fetchByNewVerifyCode = createAsyncThunk<NewCodeData, number, { rejectValue: string }>(
    'user/fetchByNewVerifyCode',
    async (id, { rejectWithValue }) => {
        const res = await platformAPI.newVerifyCode(id)
        // console.log(res);

        if (res.status !== 201) {
            return rejectWithValue('Error')
        }

        return res.data
    }
)

export const loginFetchByToken = createAsyncThunk<string, TokenData, { rejectValue: string }>(
    'user/loginFetchByToken',
    async (data, { rejectWithValue }) => {
        const res = await platformAPI.loginToken(data)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Error')
        }

        return res.data?.access
    }
)

export const fetchUserProfile = createAsyncThunk<UserProfileData, string, { rejectValue: string }>(
    'user/fetchUserProfile',
    async (token, { rejectWithValue }) => {

        const res = await platformAPI.getUser(token)

        if (res.status !== 200) {
            return rejectWithValue('Login error')
        }

        return res.data
    }
)

export const fetchByUpdateUserInfo = createAsyncThunk<UserUpdatedData, NewUserData, { rejectValue: string }>(
    'user/fetchByUpdateUserInfo',
    async (data, { rejectWithValue }) => {
        const res = await platformAPI.updateUserInfo(data)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Upload Error')
        }

        return res.data
    }
)

export const fetchByChangePass = createAsyncThunk<string, ChangePassData, { rejectValue: string }>(
    'user/fetchByChangePass',
    async (data, { rejectWithValue }) => {
        const res = await platformAPI.changePass(data)
        // console.log(res);

        if (res.status !== 201) {
            return rejectWithValue('Something went wrong!')
        }

        return res.data?.message
    }
)

export const fetchByNewPass = createAsyncThunk<string, NewPassData, { rejectValue: string }>(
    'user/fetchByNewPass',
    async (data, { rejectWithValue }) => {
        const res = await platformAPI.addNewPass(data)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Something went wrong!')
        }

        return 'res.data?.message'
    }
)

export const fetchByDeleteUserAvatar = createAsyncThunk<string, string, { rejectValue: string }>(
    'user/fetchByDeleteUserAvatar',
    async (token, { rejectWithValue }) => {
        const res = await platformAPI.deleteUserAvatar(token)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Something went wrong!')
        }

        return res.data?.avatar
    }
)

const initialState: UserState = {
    user_email: '',
    user_name: '',
    user_phone: '',
    user_pass: '',
    user_pass_repeat: '',
    login_modal: false,
    regist_modal: false,
    confirm_logout_modal: false,
    account_modal: false,
    verify_modal: false,
    change_pass_modal: false,
    success_change_pass: '',
    redirect_after_change: false,
    user_id: null,
    token: '',
    error_message: false,
    user_profile: {
        avatar: null,
        email: '',
        first_name: null,
        id: null,
        last_name: null,
        phone_number: null,
        username: '',
    }
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        toggleLogin(state, action) {
            state.login_modal = action.payload
        },
        toggleRegist(state, action) {
            state.regist_modal = action.payload
        },
        toggleConfirm(state, action) {
            state.confirm_logout_modal = action.payload
        },
        toggleAccountInfo(state, action) {
            state.account_modal = action.payload
        },
        toggleChangePass(state, action) {
            state.change_pass_modal = action.payload
        },
        writeEmail(state, action) {
            state.user_email = action.payload
        },
        writeName(state, action) {
            state.user_name = action.payload
        },
        writePass(state, action) {
            state.user_pass = action.payload
        },
        writePassRepeat(state, action) {
            state.user_pass_repeat = action.payload
        },
        setToken(state, action) {
            state.token = action.payload
        },
        toggleError(state) {
            state.error_message = false
        },
        logOut(state) {
            removeLS('token')
            state.token = ''
            state.user_name = ''
        },
        changeFirstname(state, action) {
            state.user_profile.first_name = action.payload
        },
        changeSurname(state, action) {
            state.user_profile.last_name = action.payload
        },
        changePhone(state, action) {
            state.user_profile.phone_number = action.payload
        },
    },
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(addNewUser.pending, (state) => {
            // console.log('pending');
            state.error_message = false
        })
        addCase(addNewUser.fulfilled, (state, action) => {
            if (action.payload.message === 'Successfully registration') {
                state.user_pass = ''
                state.user_pass_repeat = ''
                state.user_id = action.payload.user_id
                state.verify_modal = true
            }
        })
        addCase(addNewUser.rejected, (state, action) => {
            // console.error(action);
            if (action.payload === 'User with such username is already exists') {
                state.error_message = true
            }
        })
        addCase(verifyUser.pending, (state) => {

        })
        addCase(verifyUser.fulfilled, (state, action) => {
            if (action.payload === 'Successfuly confirmation, thanks!') {
                state.verify_modal = false
                state.regist_modal = false
                state.login_modal = true
            }
        })
        addCase(verifyUser.rejected, (state, action) => {
            console.error(action);
        })
        addCase(fetchByNewVerifyCode.pending, (state) => {

        })
        addCase(fetchByNewVerifyCode.fulfilled, (state, action) => {
            // state.code = action.payload.new_code
        })
        addCase(fetchByNewVerifyCode.rejected, (state, action) => {
            console.error(action);
        })
        addCase(loginFetchByToken.pending, (state) => {
            state.error_message = false
        })
        addCase(loginFetchByToken.fulfilled, (state, action) => {
            state.token = action.payload
            setLS('token', action.payload)
            state.login_modal = false
        })
        addCase(loginFetchByToken.rejected, (state, action) => {
            // console.error(action);
            state.error_message = true
        })
        addCase(fetchUserProfile.pending, (state) => {
            state.error_message = false
        })
        addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.error_message = false
            state.user_profile = action.payload
        })
        addCase(fetchUserProfile.rejected, (state, action) => {
            // console.log(action);
            removeLS('token')
            state.token = ''
            state.login_modal = true
        })
        addCase(fetchByUpdateUserInfo.pending, (state) => {
            state.error_message = false
        })
        addCase(fetchByUpdateUserInfo.fulfilled, (state, action) => {
            state.user_profile.avatar = action.payload.avatar
            state.user_profile.first_name = action.payload.first_name
            state.user_profile.last_name = action.payload.last_name
            state.user_profile.phone_number = action.payload.phone_number
        })
        addCase(fetchByUpdateUserInfo.rejected, (state, action) => {
            state.error_message = true
        })
        addCase(fetchByChangePass.pending, (state) => {
            state.error_message = false
        })
        addCase(fetchByChangePass.fulfilled, (state, action) => {
            state.success_change_pass = action.payload
        })
        addCase(fetchByChangePass.rejected, (state, action) => {
            state.error_message = true
        })
        addCase(fetchByNewPass.pending, (state) => {
            state.error_message = false
        })
        addCase(fetchByNewPass.fulfilled, (state, action) => {
            state.redirect_after_change = true
        })
        addCase(fetchByNewPass.rejected, (state, action) => {
            state.error_message = true
        })
        addCase(fetchByDeleteUserAvatar.pending, (state) => {
            state.error_message = false
        })
        addCase(fetchByDeleteUserAvatar.fulfilled, (state, action) => {
            state.user_profile.avatar = action.payload
        })
        addCase(fetchByDeleteUserAvatar.rejected, (state, action) => {
            state.error_message = true
        })
    }
})

export const {
    toggleLogin,
    toggleRegist,
    writeEmail,
    writeName,
    writePass,
    writePassRepeat,
    setToken,
    toggleError,
    logOut,
    toggleConfirm,
    toggleAccountInfo,
    changeFirstname,
    changeSurname,
    changePhone,
    toggleChangePass, } = userSlice.actions

export default userSlice.reducer