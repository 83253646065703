import React, { FC } from 'react'
import s from './Employment.module.css'
import { CoopCompaniesData } from '../../store/modules'

interface EmployeeCardProps {
    item: CoopCompaniesData
}

const EmployeeCard: FC<EmployeeCardProps> = ({ item }) => {
    const { image } = item
    return (
        <div className={s.employersCard}>
            <img src={`https${image.slice(4)}`} alt="VK" />
        </div>
    )
}

export default EmployeeCard