import React, { FC, useEffect } from 'react';
import s from './MyCourses.module.css'
import HelloProfile from './HelloProfile/HelloProfile';
import { useTranslation } from 'react-i18next';
import star from '../../assets/images/star.png'
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByUserCourses } from '../../store/slices/userCoursesSlice';
import List from './List/List';
import { Helmet } from 'react-helmet-async';

const MyCourses: FC = () => {
    const { t, i18n } = useTranslation()
    const { token } = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()
    const lang = i18n.language

    useEffect(() => {
        if (token) {
            dispatch(fetchByUserCourses(token))
            window.scrollTo(0, 0)
        }
    }, [dispatch, token])
    return (
        <div className={s.wrapper}>
            <Helmet>
                <title>
                    {lang === 'ru' ? 'Мои курсы | ITCbootcamp' : 'My courses | ITCbootcamp'}
                </title>
            </Helmet>
            <HelloProfile />
            <div className={s.container}>
                <div className={s.title_block}>
                    <h2>{t("my_courses.hello_profile.title")}</h2>
                    <Link to={'/user-whishlist'}>
                        <img className={s.star} src={star} alt="star" />
                        {t("my_courses.hello_profile.featured_link")}
                    </Link>
                </div>
                <List />
            </div>
        </div>
    );
};

export default MyCourses;