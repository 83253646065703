import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByNewPass, toggleLogin } from '../../store/slices/userSlice';
import s from './ResetPassword.module.css'
import { Helmet } from 'react-helmet-async';

const ResetPassword: FC = () => {
    const { t } = useTranslation()
    const { error_message, redirect_after_change } = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [error, setError] = useState('')
    const [userPass, setUserPass] = useState({
        pass: '',
        pass_repeat: ''
    })

    const toggleForm: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        if (userPass.pass === userPass.pass_repeat && userPass.pass.length >= 8 && userPass.pass_repeat.length >= 8) {
            const linkToken = searchParams.get('token') || ''
            setError('')
            dispatch(fetchByNewPass({ linkToken, password: userPass.pass }))
        } else if (userPass.pass.length < 8 || userPass.pass_repeat.length < 8) {
            setError(t("auth.pass_text"))
        } else {
            setError(t("auth.pass_err"))
        }
    }

    useEffect(() => {
        if (redirect_after_change) {
            navigate('/', { replace: true })
            dispatch(toggleLogin(true))
        }
    })

    return (
        <div className={`${s.wrapper} animate__animated animate__fadeIn animate__faster`}>
            <Helmet>
                <title>
                    {t("auth.new_pass")} | ITCBootcamp.com
                </title>
            </Helmet>
            <div className={s.container}>
                <h2 className={s.title}>{t("auth.new_pass")}</h2>
                <form onSubmit={toggleForm} className={s.new_pass_form}>
                    <input
                        onChange={(e) => setUserPass({ ...userPass, pass: e.target.value })}
                        className={s.inputs} type="password" placeholder={t("auth.pass")} />
                    <input
                        onChange={(e) => setUserPass({ ...userPass, pass_repeat: e.target.value })}
                        className={s.inputs} type="password" placeholder={t("auth.pass_repeat")} />
                    <p className={s.pass_text}>{t("auth.pass_text")}</p>
                    {error && <span className={s.error}>{error}</span>}
                    {error_message && <span className={s.error}>{t("auth.change_pass_err")}</span>}
                    <button className={s.continue_btn}>{t("auth.verify_btn")}</button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;