import React, { FC, useState } from 'react';
import s from './Verificate.module.css'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import VerificationInput from "react-verification-input";
import { fetchByNewVerifyCode, verifyUser } from '../../../store/slices/userSlice';

const Verificate: FC = () => {
    const { t } = useTranslation()
    const { user_email, user_id } = useAppSelector(state => state.user)
    const [newCode, setNewCode] = useState('')
    const [error, setError] = useState('')
    const dispatch = useAppDispatch()

    const toggleForm = () => {
        if (newCode.length === 6) {
            dispatch(verifyUser({ code: newCode, user_id }))
        } else {
            setError(t("auth.verify_err"));
        }
    }

    return (
        <div className={`${s.verify_wrapper} animate__animated animate__fadeIn animate__faster`}>
            <div className={s.container}>
                <h1 className={s.verify_title}>{t("auth.verify_title")}</h1>
                <p className={s.verify_info}>{t("auth.verify_info1")}{user_email}{t("auth.verify_info2")}</p>
                <div className={s.btns_wrapper}>
                    <VerificationInput onChange={(e) => setNewCode(e)} value={newCode} />
                    {error && <span className={s.error}>{error}</span>}
                    <button onClick={toggleForm} className={s.confirm_btn}>{t("auth.verify_btn")}</button>
                    <button onClick={() => user_id !== null && dispatch(fetchByNewVerifyCode(user_id))} className={s.again_btn}>{t("auth.verify_again")}</button>
                </div>
            </div>
        </div>
    );
};

export default Verificate;