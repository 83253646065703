import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import s from './Home.module.css'
import Bootcamp from './Components/Bootcamp/Bootcamp';
import Technologies from './Components/Technologies/Technologies';
import Courses from './Components/Courses/Courses';
import StudyingProcess from './Components/StudyingProcess/StudyingProcess';
import Portfolio from '../../components/Portfolio/Portfolio'
import Callback from '../../components/Callback/Callback';
import Feedback from '../../components/Feedbacks/Feedback';
import Hero from './Components/Hero/Hero';
import Certificate from '../../components/Certificate/Certificate';
import Employment from '../../components/Employment/Employment';
import { Helmet } from 'react-helmet-async';

const Home: FC = () => {
    const { i18n } = useTranslation()
    const lang = i18n.language

    return (
        <div className={s.home_section}>
            <Helmet>
                <link rel="canonical" href="https://itcbootcamp.com/" />
                <title>
                    {lang === 'ru' ? 'Курсы программирования | ITCbootcamp' : 'Programming courses | ITCbootcamp'}
                </title>
            </Helmet>
            <Hero />
            <Bootcamp />
            <Technologies />
            <Courses />
            <StudyingProcess />
            <Employment />
            <Portfolio />
            <Feedback />
            <Certificate />
            <Callback />
        </div>
    );
};

export default Home;