import React, { FC } from 'react';
import s from './ListCard.module.css'
import { VideosListData } from '../../../store/modules';
import { pathLink } from '../../../reused';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ListCardProps {
    item: VideosListData
    id_technologie: string
}

const ListCard: FC<ListCardProps> = ({ item, id_technologie }) => {
    const { i18n } = useTranslation()
    const lang = i18n.language

    return (
        <div className={s.card}>
            <Link to={`/watch/video/${item?.id}/${id_technologie}`}>
                <img className={s.preview} src={pathLink + item?.preview} alt="preview" />
            </Link>
            <h3
                title={lang === 'ru' ? item?.name_ru : item?.name_en}
                className={s.title}>
                {lang === 'ru' ?
                    item?.name_ru.length > 55 ? item?.name_ru.slice(0, 55) + '...' : item?.name_ru
                    : item?.name_en.length > 55 ? item?.name_en.slice(0, 55) + '...' : item?.name_en}
            </h3>
        </div>
    );
};

export default ListCard;