import React, { FC } from 'react'
import { ResumeData } from '../../../../../store/modules'
import s from './ResumeTechnology.module.css'

interface ResumeTechnologyProps {
    resume: ResumeData
}

const ResumeTechnology: FC<ResumeTechnologyProps> = ({ resume }) => {

    const { resume_technologies } = resume
    return (
        <div className={s.technology_block}>
            {
                resume_technologies.map((stack, i) => (
                    <div key={i} className={s.technology_item}>
                        {stack.name}
                    </div>
                ))
            }
        </div>
    )
}

export default ResumeTechnology