import React, { FC } from 'react'
import s from '../SkillsYouGain.module.css'
import { TechnologiesData } from '../../../../../store/modules'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface TechnologiesCardProps {
    technology: TechnologiesData
    course_id: number
}

const TechnologiesCard: FC<TechnologiesCardProps> = ({ technology, course_id }) => {

    const { logo, name_en, name_ru, description_en, description_ru, id } = technology
    const { i18n } = useTranslation()
    const lang = i18n.language

    return (
        <Link className={s.link_item} to={`/technologie/${id}/${name_en}/?course_id=${course_id}`}>

            <div className={s.imgBlock}>
                <img className={s.technologyLogo} src={`https${logo.slice(4)}`} alt={lang === 'ru' ? name_ru : name_en} />
            </div>
            <h5 className={s.technologyName}>
                {lang === 'ru' ? name_ru : name_en}
            </h5>
            <p className={s.technologyDescription} title={lang === 'ru' ? description_ru : description_en}>
                {lang === 'ru' ? description_ru.slice(0, 40) + '...' : description_en.slice(0, 40) + '...'}
            </p>

        </Link>

    )
}

export default TechnologiesCard