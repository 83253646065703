import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import s from './Navigation.module.css'
import { useAppSelector } from '../../store/hooks/hooks';

interface NavigationProps {
    showSettings: boolean
    setShowSettings: Dispatch<SetStateAction<boolean>>
}

const Navigation: FC<NavigationProps> = ({ setShowSettings, showSettings }) => {
    const { courses } = useAppSelector(state => state.courses)
    const { t, i18n } = useTranslation()
    const lang = i18n.language

    const closeSettings = () => {
        setShowSettings(false)
    }


    const toggleLang = (lang_name: string) => {
        i18n.changeLanguage(lang_name)
    }
    return (
        <nav className={s.nav}>
            <ul className={s.menu}>
                {
                    courses.length > 0 &&
                    courses?.slice(0, 4)?.map(el => (
                        <li key={el.id}><NavLink
                            onClick={closeSettings}
                            className={({ isActive }) => isActive ? `${s.menu__items} ${s.active}` : s.menu__items}
                            to={`/courses/${el.id}/${el.name_en}`}>{el.name_en}</NavLink>
                        </li>

                    ))
                }
                <li>|</li>
                <li>
                    <NavLink
                        onClick={closeSettings}
                        className={({ isActive }) => isActive ? `${s.active} ${s.menu__items}` : s.menu__items}
                        to={'/list_courses'}>{t("header.navigation.link2")}</NavLink>
                </li>
                <li>|</li>
                <li><NavLink
                    onClick={closeSettings}
                    className={({ isActive }) => isActive ? `${s.active} ${s.menu__items}` : s.menu__items}
                    to={'/about_us'}>{t("header.navigation.link")}</NavLink></li>
                <div className={s.lang_block}>
                    <span onClick={() => toggleLang('ru')} className={lang === 'ru' ? `${s.active_lang} ${s.lang}` : s.lang}>RU</span>
                    <span onClick={() => toggleLang('en')} className={lang === 'en' ? `${s.active_lang} ${s.lang}` : s.lang}>EN</span>
                </div>
            </ul>
        </nav>
    );
};

export default Navigation;