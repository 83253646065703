import React, { FC, Dispatch, SetStateAction } from 'react';
import { BookingCoursesData } from '../../../store/modules';
import s from './BookingCard.module.css'
import './Label.css'
import { useTranslation } from 'react-i18next';

type CurrentCourseData = {
    amount: string
    name: string
    city: string
}

interface BookingCardProps {
    item: BookingCoursesData
    setCourse: Dispatch<SetStateAction<number>>
    currentCourse: CurrentCourseData
    setCurrentCourse: Dispatch<SetStateAction<CurrentCourseData>>
}

const BookingCard: FC<BookingCardProps> = ({ item, setCourse, currentCourse, setCurrentCourse }) => {
    const { t, i18n } = useTranslation()
    const lang = i18n.language
    const {
        start_date_ru,
        start_date_en,
        mentor_name_ru,
        mentor_name_en,
        amount,
        id
    } = item
    return (
        <label onClick={() => {
            setCourse(id)
            setCurrentCourse({ ...currentCourse, amount: `${amount}`, name: `Бронь курса на ${start_date_ru}` })
        }} className={`${s.label_choose} control control-radio`}>
            <input type="radio" name='course' />
            <div className="control_indicator"></div>
            <div className={s.info_block}>
                <h3 className={s.when}>{lang === 'ru' ? start_date_ru : start_date_en}</h3>
                <div className={s.bottom_block}>
                    <p className={s.mentor_block}>
                        {t("booking.mentor")}
                        <span className={s.mentor_name}>{lang === 'ru' ? mentor_name_ru : mentor_name_en}</span>
                    </p>
                    <p className={s.mentor_block}>
                        {t("booking.price")}
                        <span className={s.mentor_name}>{amount}{currentCourse.city === "Алматы" ? " KZT" : " KGS"}</span>
                    </p>
                </div>
            </div>
        </label>
    );
};

export default BookingCard;