import React, { FC, Dispatch, SetStateAction, useEffect } from 'react';
import s from './MentorModal.module.css'
import { MentorsData } from '../../../store/modules';
import { useTranslation } from 'react-i18next';

interface MentorModalProps {
    item: MentorsData
    setShowMentor: Dispatch<SetStateAction<boolean>>
    showMentor: boolean
}

const MentorModal: FC<MentorModalProps> = ({ item, setShowMentor, showMentor }) => {
    const { i18n } = useTranslation()
    const lang = i18n.language
    const {
        description_ru,
        description_en,
        avatar,
        cource_name,
        full_name_en,
        full_name_ru,
        level,
        work_experience,
        work_place,
        city } = item

    const closeModal = () => {
        setShowMentor(false)
    }

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                closeModal()
            }
        })
    }, [setShowMentor])

    useEffect(() => {
        if (showMentor) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = (e) => {
                closeModal()
            };
        }
        return () => {
            if (!showMentor) window.history.back();
            window.onpopstate = () => { };
        };
    }, [showMentor])



    return (
        <div onClick={closeModal} className={`${s.wrapper} animate__animated animate__fadeIn animate__faster`}>
            <div
                onClick={(e) => e.stopPropagation()}
                className={s.container}>
                <img className={s.avatar} src={`https${avatar.slice(4)}`} alt="avatar" />
                <div className={s.info_block}>
                    <h3 className={s.mentor_name}>{lang === 'ru' ? full_name_ru : full_name_en}</h3>
                    <h5 className={s.work_place}>{work_place}</h5>
                    <h3 className={s.mentor_name}>{cource_name}</h3>
                    <h5 className={s.work_place}>{level}</h5>
                    <h5 className={s.mentor_name}>{work_experience}</h5>
                    <h5 className={s.work_place}>{city}</h5>
                    <h3 className={s.mentor_name}>{lang === 'ru' ? description_ru : description_en}</h3>
                </div>
            </div>
        </div>
    );
};

export default MentorModal;