import { configureStore } from "@reduxjs/toolkit";
import infoSlice from './slices/infoSlice';
import userSlice from "./slices/userSlice";
import coursesSlice from "./slices/coursesSlice";
import userCoursesSlice from "./slices/userCoursesSlice";


const store = configureStore({
    reducer: {
        info: infoSlice,
        user: userSlice,
        courses: coursesSlice,
        list_courses: userCoursesSlice,
    },
    devTools: false,
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch