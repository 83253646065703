import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import s from './SettingsModal.module.css'
import account_img from '../../../assets/images/profile-circle.png'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import my_courses from '../../../assets/images/Mortarboard.png'
import logout from '../../../assets/images/logout.png'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { toggleAccountInfo, toggleConfirm } from '../../../store/slices/userSlice';
import ConfirmLogout from '../ConfirmLogout/ConfirmLogout';
import AccountModal from '../AccountModal/AccountModal';

type SettingsModalProps = {
    setShowSettings: Dispatch<SetStateAction<boolean>>
}

const SettingsModal: FC<SettingsModalProps> = ({ setShowSettings }) => {
    const { confirm_logout_modal, account_modal } = useAppSelector(state => state.user)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                setShowSettings(false)
            }
        })
    }, [dispatch])

    return (
        <div onClick={(e) => e.stopPropagation()} className={s.settings_wrapper}>
            <div onClick={() => dispatch(toggleAccountInfo(true))} className={s.choice_block}>
                <img className={s.acc_img} src={account_img} alt="Account" />
                <h4 className={s.acc_title}>{t("profile.account_title")}</h4>
            </div>
            <Link onClick={() => setShowSettings(false)} className={s.choice_block} to={'/my_courses'}>
                <img src={my_courses} alt="my_courses" />
                <h4>{t("profile.courses_title")}</h4>
            </Link>
            <div onClick={(e) => {
                e.stopPropagation()
                dispatch(toggleConfirm(true))
            }} className={s.choice_block}>
                <img src={logout} alt="logout" />
                <h4>{t("profile.logout")}</h4>
            </div>
            {confirm_logout_modal && <ConfirmLogout setShowSettings={setShowSettings} />}
            {account_modal && <AccountModal setShowSettings={setShowSettings} />}
        </div>
    );
};

export default SettingsModal;