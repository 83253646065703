import React, { FC } from 'react'
import s from './Description.module.css'
import img from '../../../assets/images/HomePage/watch_msg.png'
import { TechnologiesData } from '../../../store/modules'
import { useTranslation } from 'react-i18next'

interface DescriptionProps {
    technologie: TechnologiesData
}

const Description: FC<DescriptionProps> = ({ technologie }) => {
    const { name_en, name_ru, description_en, description_ru } = technologie
    const { t, i18n } = useTranslation()
    const lang = i18n.language

    return (
        <section className={s.description_section}>
            <div className={s.container}>
                <div className={s.bg}>
                    <div className={s.dark_bg} />
                    <div className={s.card}>

                        <div className={s.leftSide}>
                            <h5 className={s.section_title}>
                                {lang === 'ru' ? name_ru : name_en}
                            </h5>
                            <h2 className={s.title_description}>
                                {`${t("course.description.whoIsDeveloper")} ${name_en} ?`}
                            </h2>
                            <span className={s.description}>
                                {lang === 'ru' ? description_ru : description_en}
                            </span>
                        </div>

                        <div className={s.rightSide}>
                            <img className={s.rightSideImage} src={img} alt="img" />
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Description