import React, { FC } from 'react'
import s from './Courses.module.css'
import CoursesCards from './CoursesCards'
import { useAppSelector } from '../../../../store/hooks/hooks'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { useTranslation } from 'react-i18next'

const Courses: FC = () => {
    const { courses } = useAppSelector(state => state.courses)
    const { t } = useTranslation()
    return (
        <section className={s.courses_section}>
            <div className={s.container}>
                <h5 className={s.section_title}>{t("home.courses-section.section-title")}</h5>
                <h2 className={s.section_title_des}>{t("home.courses-section.title-description")}</h2>

                <AnimationOnScroll offset={300} animateOnce={true} animateIn='animate__faster animate__fadeInRight'>
                    <div className={s.courses_cards}>
                        {
                            courses.map((course) => (
                                <CoursesCards
                                    key={course.id}
                                    course={course}
                                />
                            ))
                        }
                    </div>
                </AnimationOnScroll>

            </div>
        </section>
    )
}

export default Courses