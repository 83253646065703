import React, { FC } from 'react'
import s from './Feedback.module.css'
import quoteMark from '../../assets/images/HomePage/quote_mark.png'
import { ReviewData } from '../../store/modules'
import { pathLink } from '../../reused'

interface FeedbackCardProps {
    info: ReviewData
}

const FeedbackCard: FC<FeedbackCardProps> = ({ info }) => {
    const { avatar, comment, first_name, last_name } = info

    return (
        <div className={s.feedback_card}>
            <img className={s.quoteMark} src={quoteMark} alt="quote" />
            <p title={comment} className={s.feedback_content}>
                {comment.length > 230 ? comment?.slice(0, 230) + '...' : comment}
            </p>
            <div className={s.feedback_author}>
                <div>
                    <img className={s.avatar} src={pathLink + avatar} alt="avatar" />
                </div>
                <div>
                    <h6 className={s.authors_name}>{`${first_name ? first_name : 'Анонимный'} ${last_name ? last_name : 'пользователь'}`}</h6>
                </div>
            </div>
        </div>
    )
}

export default FeedbackCard