import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import s from './CitiesModal.module.css'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { toggleCitiesModal } from '../../../store/slices/infoSlice';
import closeImg from '../../../assets/images/close.png'
import wa from '../../../assets/images/Whatsapp.png'
import tg from '../../../assets/images/Telegram.png'
import inst from '../../../assets/images/Instagram.png'
import { useTranslation } from 'react-i18next';


interface CitiesModalProps {
    chooseName: string
    cities_modal: boolean
    setChooseName: Dispatch<SetStateAction<string>>
}

const CitiesModal: FC<CitiesModalProps> = ({ cities_modal, chooseName, setChooseName }) => {
    const dispatch = useAppDispatch()
    const { company } = useAppSelector(state => state.info)
    const { t } = useTranslation()

    const closeModal = () => {
        setChooseName('')
        dispatch(toggleCitiesModal(false))
    }

    useEffect(() => {
        if (cities_modal) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = (e) => {
                closeModal()
            };
        }
        return () => {
            if (!cities_modal) window.history.back();
            window.onpopstate = () => { };
        };
    }, [cities_modal, dispatch])

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                closeModal()
            }
        })
    }, [dispatch])

    return (
        <div onClick={closeModal} className={`${s.wrapper} animate__animated animate__fadeIn animate__faster`}>
            <div onClick={(e) => e.stopPropagation()} className={s.container}>
                <img onClick={closeModal} className={s.close} src={closeImg} alt="close" />

                {
                    chooseName === 'telegram' &&
                    <>
                        <a href={company?.telegram_almata || ""} target="_blank" rel="noopener noreferrer">
                            <img src={tg} alt="telegram" />{t("cities.city1")}
                        </a>
                        <a href={company?.telegram_bishkek || ""} target="_blank" rel="noopener noreferrer">
                            <img src={tg} alt="telegram" />{t("cities.city2")}
                        </a>
                        {/* <a href={company?.telegram_tashkent || ""} target="_blank" rel="noopener noreferrer">
                            <img src={tg} alt="telegram" />{t("cities.city3")}
                        </a> */}
                        <a href={company?.telegram_osh || ""} target="_blank" rel="noopener noreferrer">
                            <img src={tg} alt="telegram" />{t("cities.city4")}
                        </a>
                        {/* <a href={company?.telegram_jalalabad || ""} target="_blank" rel="noopener noreferrer">
                            <img src={tg} alt="telegram" />{t("cities.city5")}
                        </a> */}
                    </>
                }

                {
                    chooseName === 'instagram' &&
                    <>
                        <a href={company?.instagram_almata || ""} target="_blank" rel="noopener noreferrer">
                            <img src={inst} alt="instagram" />{t("cities.city1")}
                        </a>
                        <a href={company?.instagram_bishkek || ""} target="_blank" rel="noopener noreferrer">
                            <img src={inst} alt="instagram" />{t("cities.city2")}
                        </a>
                        {/* <a href={company?.instagram_tashkent || ""} target="_blank" rel="noopener noreferrer">
                            <img src={inst} alt="instagram" />{t("cities.city3")}
                        </a> */}
                        <a href={company?.instagram_osh || ""} target="_blank" rel="noopener noreferrer">
                            <img src={inst} alt="instagram" />{t("cities.city4")}
                        </a>
                        {/* <a href={company?.instagram_jalalabad || ""} target="_blank" rel="noopener noreferrer">
                            <img src={inst} alt="instagram" />{t("cities.city5")}
                        </a> */}
                    </>
                }

                {
                    chooseName === 'whatsapp' &&
                    <>
                        <a href={company?.whatsapp_almata || ""} target="_blank" rel="noopener noreferrer">
                            <img src={wa} alt="whatsapp" />{t("cities.city1")}
                        </a>
                        <a href={company?.whatsapp_bishkek || ""} target="_blank" rel="noopener noreferrer">
                            <img src={wa} alt="whatsapp" />{t("cities.city2")}
                        </a>
                        {/* <a href={pathLink + company?.whatsapp_tashkent} target="_blank" rel="noopener noreferrer">
                            <img src={wa} alt="whatsapp" />{t("cities.city3")}
                        </a> */}
                        <a href={company?.whatsapp_osh || ""} target="_blank" rel="noopener noreferrer">
                            <img src={wa} alt="whatsapp" />{t("cities.city4")}
                        </a>
                        {/* <a href={company?.whatsapp_jalalabad || ""} target="_blank" rel="noopener noreferrer">
                            <img src={wa} alt="whatsapp" />{t("cities.city5")}
                        </a> */}
                    </>
                }

            </div>
        </div>
    );
};

export default CitiesModal;