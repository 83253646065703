import React, { FC } from 'react'
import s from './Technologies.module.css'
import { t } from 'i18next'
import { CourseData } from '../../../../store/modules'
import TechnologiesIcon from './TechnologyIcon/TechnologiesIcon'
import { useTranslation } from 'react-i18next'

interface TechnologiesProps {
    course: CourseData
}

const Technologies: FC<TechnologiesProps> = ({ course }) => {

    const { i18n } = useTranslation()
    const lang = i18n.language
    const { technologies, name_en, name_ru } = course

    return (
        <section className={s.technologies_section}>
            <div className={s.container}>
                <div className={s.leftSide}>
                    <h5 className={s.section_title}>{t("course.technology.technology")}</h5>
                    <h2 className={s.section_title_des}>{lang === 'ru' ? name_ru : name_en} <span className={s.technology_span}>{t("course.technology.technology")}</span> </h2>
                    <span className={s.description}>{` ${t("course.technology.weWilllearn")} ${lang === 'ru' ? name_ru : name_en} ${t("course.technology.developer")}`}</span>
                </div>

                <div className={s.rightSide}>

                    {
                        technologies.map(technology => (
                            <TechnologiesIcon
                                key={technology.id}
                                technology={technology} />
                        ))
                    }

                </div>
            </div>
        </section>
    )
}

export default Technologies