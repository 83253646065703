import React, { FC } from 'react'
import s from './StudyingProcess.module.css'
import connectLine from '../../../../assets/images/HomePage/connect line.png'
import { useTranslation } from 'react-i18next'



const StudyingProcess: FC = () => {
    const { t } = useTranslation()

    return (
        <section className={s.stydyingProcess_section}>

            <div className={s.dark_bg} />
            <div className={s.container}>
                <h2 className={s.title_des}>
                    {
                        t("course.learningProcess.learningProcess")
                    }
                </h2>
                <p className={s.description}>
                    {
                        t("course.learningProcess.description")
                    }
                </p>

                <div className={s.education_processes}>
                    <div className={s.process}>
                        <div className={`${s.first_process} ${s.num}`}>1</div>
                        <p>
                            {
                                t("course.learningProcess.processOneTitle")
                            }
                        </p>
                        <span>
                            {
                                t("course.learningProcess.processOneDescription")
                            }
                        </span>
                    </div>
                    <div className={s.lineBlock} id={s.line1}>
                        <img id={s.line1} className={s.connectLine} src={connectLine} alt="connectLine" />
                    </div>
                    <div className={s.process}>
                        <div className={`${s.second_process} ${s.num}`}>2</div>
                        <p>
                            {
                                t("course.learningProcess.processTwoTitle")
                            }
                        </p>
                        <span>
                            {
                                t("course.learningProcess.processTwoDescription")
                            }
                        </span>
                    </div>
                    <div className={s.lineBlock} id={s.line2}>
                        <img id={s.line1} className={s.connectLine} src={connectLine} alt="connectLine" />
                    </div>
                    <div className={s.process}>
                        <div className={`${s.third_process} ${s.num}`}>3</div>
                        <p>
                            {
                                t("course.learningProcess.processThreeTitle")
                            }
                        </p>
                        <span>
                            {
                                t("course.learningProcess.processThreeDescription")
                            }
                        </span>
                    </div>
                    <div className={s.lineBlock} id={s.line3}>
                        <img id={s.line1} className={s.connectLine} src={connectLine} alt="connectLine" />
                    </div>
                    <div className={s.process}>
                        <div className={`${s.fourth_process} ${s.num}`}>4</div>
                        <p>
                            {
                                t("course.learningProcess.processFourTitle")
                            }
                        </p>
                        <span>
                            {
                                t("course.learningProcess.processFourDescription")
                            }
                        </span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StudyingProcess