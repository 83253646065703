import React, { FC, Dispatch, SetStateAction, useState, ChangeEventHandler, useEffect } from 'react';
import s from './AccountModal.module.css'
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../assets/images/close.png'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { changeFirstname, changePhone, changeSurname, toggleAccountInfo, fetchByDeleteUserAvatar, fetchByUpdateUserInfo } from '../../../store/slices/userSlice';
import { pathLink } from '../../../reused';

type AccountModalProps = {
    setShowSettings: Dispatch<SetStateAction<boolean>>
}

const AccountModal: FC<AccountModalProps> = ({ setShowSettings }) => {
    const [file, setFile] = useState<File>()
    const { user_profile, token, error_message, account_modal } = useAppSelector(state => state.user)
    const { avatar, last_name, first_name, phone_number, id } = user_profile
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0])
        }

    }

    const requestUpdates = () => {
        if (token) {
            dispatch(fetchByUpdateUserInfo({
                token,
                first_name: first_name || '',
                last_name: last_name || '',
                phone_number: phone_number || '',
                id: id || 0,
                avatar: file
            }))
            dispatch(toggleAccountInfo(false))
            setShowSettings(false)
        }
    }

    const hideAccountModal = (e: KeyboardEvent) => {
        if (e.keyCode === 27) {
            dispatch(toggleAccountInfo(false))
        }
    }

    const deleteAvatar = () => {
        dispatch(fetchByDeleteUserAvatar(token))
    }

    useEffect(() => {
        document.addEventListener('keydown', hideAccountModal)
    }, [dispatch])

    useEffect(() => {
        if (account_modal) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = () => {
                dispatch(toggleAccountInfo(false));
            };
        }
        return () => {
            if (!account_modal) window.history.back();
            window.onpopstate = () => { };
        };

    }, [account_modal, dispatch])

    return (
        <div className={`${s.wrapper} animate__animated animate__fadeIn animate__faster`} onClick={() => dispatch(toggleAccountInfo(false))}>
            <div className={s.container} onClick={(e) => e.stopPropagation()}>
                <div className={s.title_block}>
                    <h2 className={s.title}>{t("account.title")}</h2>
                    <img onClick={() => {
                        dispatch(toggleAccountInfo(false))
                        setShowSettings(false)
                    }} className={s.close} src={closeIcon} alt="close" />
                </div>
                <div className={s.photo_block}>
                    <img src={avatar && avatar.startsWith('http') ? `https${avatar.slice(4)}` : pathLink + avatar} alt="avatar" />
                    <input onChange={handleFileChange} type='file' accept="image/*" className={s.add_photo} />
                    {/* {t("account.add_photo")} */}
                    {user_profile.avatar && <button onClick={deleteAvatar} className={s.delete_photo}>{t("account.delete_photo")}</button>}
                </div>
                <div className={s.info_block}>
                    <div className={s.info_block__item}>
                        <h5>{t("account.name")}</h5>
                        <input onChange={(e) => dispatch(changeFirstname(e.target.value))}
                            className={s.info} type="text" value={user_profile.first_name ? user_profile.first_name : ''} />
                    </div>
                    <div className={s.info_block__item}>
                        <h5>{t("account.surname")}</h5>
                        <input onChange={(e) => dispatch(changeSurname(e.target.value))}
                            className={s.info} type="text" value={user_profile.last_name ? user_profile.last_name : ''} />
                    </div>
                    <div className={s.info_block__item}>
                        <h5>Email</h5>
                        <p className={s.info}>{user_profile.email ? user_profile.email : ''}</p>
                    </div>
                    <div className={s.info_block__item}>
                        <h5>{t("account.phone_num")}</h5>
                        <input onChange={(e) => dispatch(changePhone(e.target.value))}
                            className={s.info} type="phone" value={user_profile.phone_number ? user_profile.phone_number : ''} />
                    </div>
                </div>
                <div className={s.btns_wrapper}>
                    <button onClick={requestUpdates} className={s.save}>{t("account.save")}</button>
                    {/* <button className={s.change}>{t("account.change")}</button> */}
                </div>
                {error_message && <span className={s.error}>Oops something went wrong! Try again later!</span>}
            </div>
        </div>
    );
};

export default AccountModal;