import React, { FC } from 'react'
import s from './Bootcamp.module.css'
import img from '../../../../assets/images/HomePage/watch_msg.png'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { useTranslation } from 'react-i18next'

const Bootcamp: FC = () => {
    const { t } = useTranslation()
    return (
        <AnimationOnScroll offset={300} animateOnce={true} animateIn='animate__faster animate__fadeInLeft'>
            <section className={s.bootcamp_section}>
                <div className={s.container}>
                    <div className={s.bg}>
                        <div className={s.eclipse} />
                        <div className={s.dark_bg} />
                        <div className={s.card}>

                            <div className={s.leftSide}>
                                <h5 className={s.section_title}>
                                    Bootcamp
                                </h5>
                                <h2 className={s.title_description}>
                                    {t("home.bootcamp-description.title-description")}
                                </h2>
                                <span className={s.description}>
                                    {t("home.bootcamp-description.description")}
                                </span>
                            </div>

                            <div className={s.rightSide}>
                                <img src={img} alt="img" />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </AnimationOnScroll>
    )
}

export default Bootcamp