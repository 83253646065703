import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { toggleConfirm, logOut } from '../../../store/slices/userSlice';
import s from './ConfirmLogout.module.css'
import { useNavigate } from 'react-router-dom';
import { clearListUserCourses } from '../../../store/slices/userCoursesSlice';

type ConfirmLogoutProps = {
    setShowSettings: Dispatch<SetStateAction<boolean>>
}

const ConfirmLogout: FC<ConfirmLogoutProps> = ({ setShowSettings }) => {
    const { confirm_logout_modal } = useAppSelector(state => state.user)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const toggleConfirmed = () => {
        setShowSettings(false)
        dispatch(toggleConfirm(false))
    }

    const logoutUser = () => {
        setShowSettings(false)
        dispatch(logOut())
        dispatch(toggleConfirm(false))
        dispatch(clearListUserCourses())
        navigate('/', { replace: true })
    }

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                dispatch(toggleConfirm(false))
            }
        })
    }, [dispatch, navigate])

    useEffect(() => {
        if (confirm_logout_modal) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = (e) => {
                dispatch(toggleConfirm(false));
            };
        }
        return () => {
            if (!confirm_logout_modal) window.history.back();
            window.onpopstate = () => { };
        };

    }, [confirm_logout_modal, dispatch, navigate])
    return (
        <div onClick={() => dispatch(toggleConfirm(false))} className={`${s.wrapper} animate__animated animate__fadeIn animate__faster`}>
            <div onClick={(e) => e.stopPropagation()} className={s.container}>
                <h3 className={s.title}>{t("profile.signout_question")}</h3>
                <p className={s.question}>{t("profile.signout_question")}</p>
                <div className={s.btns_wrapper}>
                    <button onClick={toggleConfirmed} className={s.cancel}>{t("profile.cancel")}</button>
                    <button onClick={logoutUser} className={s.quit}>{t("profile.quit")}</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmLogout;