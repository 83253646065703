import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { toggleLogin, toggleRegist } from '../../store/slices/userSlice';
import Login from '../AuthScenes/Login/Login';
import Registration from '../AuthScenes/Registration/Registration';
import s from './AuthBtns.module.css'
import Verificate from '../AuthScenes/Verificate/Verificate';

const AuthBtns: FC = () => {
    const { t } = useTranslation()
    const { login_modal, regist_modal, verify_modal } = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()

    return (
        <div className={s.btns_wrapper}>
            <button onClick={() => dispatch(toggleLogin(true))} className={s.login}>{t("header.authBtns.login")}</button>
            <button onClick={() => dispatch(toggleRegist(true))} className={s.reg}>{t("header.authBtns.reg")}</button>
            {login_modal && <Login />}
            {regist_modal && <Registration />}
            {verify_modal && <Verificate />}
        </div>
    );
};

export default AuthBtns;