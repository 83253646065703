import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import PortfolioCards from '../../components/Portfolio/PortfolioCards';
import { useAppSelector } from '../../store/hooks/hooks';
import s from './AllStudentsWork.module.css'

const AllStudetsWork = () => {
    const { t, i18n } = useTranslation()
    const { student_works } = useAppSelector(state => state.info)
    const lang = i18n.language

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section>
            <Helmet>
                <link rel="canonical" href={`https://itcbootcamp.com/list_studets_work`} />
                <title>
                    {lang === 'ru' ? `Список работ студентов | ITCbootcamp` : `List of student works | ITCbootcamp`}
                </title>
            </Helmet>
            <div className={s.container}>
                <div>
                    <h3 className={s.title}>{t("home.portfolio.title")}</h3>
                </div>
                <div className={s.wrapper}>
                    {
                        student_works.length > 0 &&
                        student_works.map(el => <PortfolioCards key={el.id} item={el} />)
                    }
                </div>
            </div>
        </section>
    );
};

export default AllStudetsWork;