import React, { FC } from 'react'
import s from './Technologies.module.css'
import reactIcon from '../../../../assets/images/HomePage/react_icon.png'
import nodeIcon from '../../../../assets/images/HomePage/node_icon.png'
import jsIcon from '../../../../assets/images/HomePage/js_icon.png'
import pyIcon from '../../../../assets/images/HomePage/python_icon.png'
import figmaIcon from '../../../../assets/images/HomePage/figma-logo.png'
import gitIcon from '../../../../assets/images/HomePage/git_icon.png'
import reduxIcon from '../../../../assets/images/HomePage/toolkit.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useTranslation } from 'react-i18next'

const Technologies: FC = () => {
    const { t } = useTranslation()
    return (
        <AnimationOnScroll offset={300} animateOnce={true} animateIn='animate__faster animate__zoomIn'>
            <section className={s.technologies_section}>
                <div className={s.container}>
                    <div className={s.leftSide}>
                        <h5 className={s.section_title}>{t("home.technologies-section.section-title")}</h5>
                        <h2 className={s.section_title_des}>{t("home.technologies-section.title-description")}</h2>
                        <span className={s.description}>{t("home.technologies-section.description")}</span>
                    </div>

                    <div className={s.rightSide}>
                        <div className={s.column1}>
                            <div className={s.icon_block}>
                                <img className={s.icon} src={reactIcon} alt="react" />
                            </div>
                            <div className={s.icon_block}>
                                <img className={`${s.figmaIcon} ${s.icon}`} src={figmaIcon} alt="figma" />
                            </div>
                        </div>

                        <div className={s.column2}>
                            <div className={s.icon_block}>
                                <img className={s.icon} src={nodeIcon} alt="node" />
                            </div>
                            <div className={s.icon_block}>
                                <img className={s.icon} src={pyIcon} alt="pyIcon" />
                            </div>
                            <div className={s.icon_block}>
                                <img className={s.icon} src={gitIcon} alt="git" />
                            </div>

                        </div>

                        <div className={s.column3}>
                            <div className={s.icon_block}>
                                <img className={s.icon} src={jsIcon} alt="JS" />
                            </div>
                            <div className={s.icon_block}>
                                <img className={s.icon} src={reduxIcon} alt="Redux" />
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </AnimationOnScroll>
    )
}

export default Technologies