import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import AboutUs from '../../Pages/AboutUs/AboutUs';
import AllCourses from '../../Pages/AllCourses/AllCourses';
import Course from '../../Pages/Course/Course';
import Home from '../../Pages/Home/Home';
import NotFound from '../../Pages/NotFound/NotFound';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByCoopCompanies, fetchByStudentWorks, fetchCompanyInfo } from '../../store/slices/infoSlice';
import { fetchAllCourses } from '../../store/slices/coursesSlice';
import ResetPassword from '../../Pages/ResetPassword/ResetPassword';
import Technologie from '../../Pages/Technologie/Technologie';
import AllStudentsWork from '../../Pages/AllStudetsWork/AllStudentsWork';
import Booking from '../../Pages/Booking/Booking';
import MyCourses from '../../Pages/MyCourses/MyCourses';
import InfoPages from '../InfoPages/InfoPages';
import ListMyCourses from '../../Pages/ListMyCourses/ListMyCourses';
import Video from '../../Pages/Video/Video';
import Whishlist from '../../Pages/Whishlist/Whishlist';
import VideoByFavor from '../../Pages/VideoByFavor/VideoByFavor';
import Thanks from '../../Pages/Thanks/Thanks';

const Main = () => {
    const dispatch = useAppDispatch()
    const { token } = useAppSelector(state => state.user)

    useEffect(() => {
        dispatch(fetchCompanyInfo())
        dispatch(fetchAllCourses())
        dispatch(fetchByStudentWorks())
        dispatch(fetchByCoopCompanies())
    }, [dispatch])

    return (
        <main className='main'>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/courses/:id/:name' element={<Course />} />
                <Route path='/technologie/:id/:name' element={<Technologie />} />
                <Route path='/booking_course/:id' element={<Booking />} />
                <Route path='/list_courses' element={<AllCourses />} />
                <Route path='/list_studets_work' element={<AllStudentsWork />} />
                <Route path='/about_us' element={<AboutUs />} />
                <Route path='/users/reset_password/' element={<ResetPassword />} />
                <Route path='/info_pages/:page_name' element={<InfoPages />} />
                <Route path='*' element={<NotFound />} />
                {token && <Route path='/my_courses' element={<MyCourses />} />}
                {token && <Route path='/my_courses/list-by-technologie/:id_technologie' element={<ListMyCourses />} />}
                {token && <Route path='/watch/video/:video_id/:technologie_id' element={<Video />} />}
                {token && <Route path='/user-whishlist' element={<Whishlist />} />}
                {token && <Route path='/videos-from-whishlist/:video_id' element={<VideoByFavor />} />}
                {token && <Route path='/thanks/ijdfnuhsdfhsukdsfhbfshudiu' element={<Thanks />} />}
            </Routes>
        </main>
    );
};

export default Main;