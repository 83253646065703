import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import s from './Registration.module.css'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { addNewUser, toggleRegist, toggleLogin, toggleError, writeEmail, writeName, writePass, writePassRepeat } from '../../../store/slices/userSlice';
import { Link } from 'react-router-dom';
import { validateEmail } from '../../../reused';

const Registration: FC = () => {
    const { t } = useTranslation()
    const [showInputs, setShowInputs] = useState(false)
    const [showClue, setShowClue] = useState(false)
    const [showPass, setShowPass] = useState(false)
    const [error, setError] = useState('')
    const { regist_modal, user_email, user_pass, user_pass_repeat, user_name, error_message } = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()

    const toggleForm = () => {
        if (!validateEmail(user_email) && user_pass.length >= 8 && user_pass && user_pass_repeat && user_pass === user_pass_repeat) {
            setError('')
            dispatch(addNewUser({ user_email, user_name, user_pass, user_pass_repeat }))
        } else if (showInputs && (user_pass.length < 8 || user_pass_repeat.length < 8)) {
            setError(t("auth.pass_text"))
            dispatch(toggleError())
        } else if (showInputs && user_pass !== user_pass_repeat) {
            setError(t("auth.pass_err"))
            dispatch(toggleError())
        } else if (validateEmail(user_email)) {
            setError(t("auth.email_error"))
            dispatch(toggleError())
        } else if (user_name.trim().length === 0) {
            setError(t("auth.name_err"))
            dispatch(toggleError())
        } else if (!validateEmail(user_email)) {
            setShowInputs(true)
            setError('')
            dispatch(toggleError())
        }
    }

    const toggleModals = () => {
        dispatch(toggleRegist(false))
        dispatch(toggleLogin(true))
    }

    useEffect(() => {
        if (regist_modal) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = (e) => {
                dispatch(writePass(''))
                dispatch(writePassRepeat(''))
                dispatch(toggleRegist(false));
            };
        }
        return () => {
            if (!regist_modal) window.history.back();
            window.onpopstate = () => { };
        };
    }, [regist_modal, dispatch]);

    return (
        <div
            onClick={() => dispatch(toggleRegist(false))}
            className={`${s.wrapper} animate__animated animate__fadeIn animate__faster`}>
            <div onClick={(e) => e.stopPropagation()} className={s.container}>
                <h2 className={s.title}>{t("auth.regist")}</h2>
                <div className={s.inputs_block}>
                    <input
                        onChange={(e) => dispatch(writeEmail(e.target.value))}
                        value={user_email}
                        className={`${s.inputs}`}
                        type="email" placeholder={t("auth.email")} />
                    <input
                        onChange={(e) => dispatch(writeName(e.target.value))}
                        value={user_name}
                        className={`${s.inputs}`}
                        type="text" placeholder={t("auth.name")} />
                    {
                        showInputs &&
                        <div>
                            <input
                                onFocus={() => setShowClue(true)}
                                onChange={(e) => dispatch(writePass(e.target.value))}
                                value={user_pass}
                                className={`${s.inputs} animate__animated animate__flipInX`}
                                type={showPass ? 'text' : 'password'} placeholder={t("auth.pass")} />
                            <input
                                onChange={(e) => dispatch(writePassRepeat(e.target.value))}
                                value={user_pass_repeat}
                                className={`${s.inputs} animate__animated animate__flipInX`}
                                type={showPass ? 'text' : 'password'} placeholder={t("auth.pass_repeat")} />
                            {showClue && <span className={s.pass_text}>{t("auth.pass_text")}</span>}
                            <label className={s.show_pass}>
                                <input onChange={() => setShowPass(!showPass)} id="demo_opt_1" className={s.show_pass_inp} type="checkbox" />
                                <label htmlFor="demo_opt_1">{t("auth.show_pass")}</label>
                            </label>
                        </div>
                    }
                    <button onClick={toggleForm} className={s.continue_btn}>{t("auth.btn")}</button>
                    {error && <span className={s.error}>{error}</span>}
                    {error_message && <span className={s.error}>{t("auth.regist_err")}</span>}
                </div>

                <div>
                    <p className={s.login_text}>{t("auth.redirect_login")} <span onClick={toggleModals} className={s.redirect}>{t("auth.link_login")}</span></p>
                    <p className={s.text_info}>
                        {t("auth.info")}
                        <Link onClick={() => dispatch(toggleRegist(false))} to={'/info_pages/privacy_policy'}>{t("auth.privacy")}</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Registration;