import React, { FC } from 'react';
import s from './Hero.module.css'
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/about_us_logo.png'

const Hero: FC = () => {
    const { t } = useTranslation()
    return (
        <section className={s.hero_section}>
            <div className={s.dark_bg} />

            <div className={s.container}>
                <div className={`${s.leftSide} ${s.block}`}>
                    <h1 className={s.main_title}>ITC Bootcamp</h1>
                    <p className={s.title_description}>{t("about_us.under_title")}</p>
                </div>
                <div className={`${s.rightSide} ${s.block}`}>
                    <img src={logo} alt="logo_abotus" />
                </div>
            </div>
        </section>
    );
};

export default Hero;