import React, { FC, useEffect } from 'react'
import s from './Resume.module.css'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks'
import { fetchResumeByCourse } from '../../../../store/slices/coursesSlice'
import ResumeTechnology from './ResumeTechnology/ResumeTechnology'
import ResumeSkills from './ResumeSkills/ResumeSkills'
import ResumeProjects from './ResumeProjects/ResumeProjects'

import avatar from '../../../../assets/images/HomePage/avatar.png'
import { CourseData } from '../../../../store/modules'
import { useTranslation } from 'react-i18next'

interface ResumeProps {
    id: number
    course: CourseData
}

const Resume: FC<ResumeProps> = ({ id, course }) => {
    const dispatch = useAppDispatch()
    const { resume } = useAppSelector(state => state.courses)
    const { name_en, name_ru } = course
    const { t, i18n } = useTranslation()
    const lang = i18n.language

    useEffect(() => {
        dispatch(fetchResumeByCourse(id))
    }, [dispatch, id])

    return (
        <div className={s.resume_section}>
            <div className={s.container}>
                <h2 className={s.resume_title}>
                    {t("course.resume.resume")}
                </h2>

                <div className={s.resume_card}>
                    <div className={s.resume_top}>
                        <div className={s.leftSide}>
                            <div>
                                <img className={s.avatar} src={avatar} alt="Profile pic" />
                            </div>
                            <div className={s.direction}>
                                {lang === 'ru' ? name_ru : name_en}
                            </div>
                        </div>

                        <div className={s.rightSide}>
                            {t("course.resume.payment")}
                        </div>
                    </div>

                    <div className={s.resume_main}>
                        <div className={s.stack_info}>
                            <div className={s.stack_info_title}>
                                {t("course.resume.technology")}
                            </div>
                            <div className={s.stack_info_list}>
                                {!resume ? null : <ResumeTechnology resume={resume} />}
                            </div>

                        </div>

                        <div className={s.skills_info}>
                            <div className={s.skills_info_title}>
                                {t("course.resume.mySkills")}
                            </div>
                            <div className={s.skills_list}>
                                <ul>
                                    {!resume ? null : <ResumeSkills resume={resume} />}
                                </ul>
                            </div>
                        </div>
                        <div className={s.projects_info}>
                            <div className={s.projects_info_title}>
                                {t("course.resume.MyProjects")}
                            </div>
                            <div className={s.projects_list}>
                                {!resume ? null : <ResumeProjects resume={resume} />}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resume