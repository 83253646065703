import React, { FC, useState } from 'react'
import s from './Quetions.module.css'
import arrowDown from '../../../assets/images/arrowDown.png'
import { useTranslation } from 'react-i18next'
import { FAQData } from '../../../store/modules'

type QuestionProps = {
    questions: FAQData
}

const Questions: FC<QuestionProps> = ({ questions }) => {
    const { question_ru, question_en, answer_ru, answer_en, id } = questions
    const [openId, setOpenId] = useState<null | number>(null)
    const { i18n } = useTranslation()
    const lang = i18n.language

    const toggleAnswer = (id: number) => {
        setOpenId(openId === id ? null : id)
    }

    return (

        <div className={s.faq_card}>
            <div className={s.question_container}>
                <div className={s.question} onClick={() => toggleAnswer(id)}>
                    <p>
                        {lang === 'ru' ? question_ru : question_en}
                    </p>
                    <img className={openId === id ? `${s.arrow_up}` : `${s.close_question} ${s.close}`} src={arrowDown} alt="arrow" />
                </div>
                {
                    openId === id && (<div
                        className={`${openId === id ? `${s.answerOpen} animate__animated  animate__bounceInLeft animate__faster` : `${s.answer}`}`}>
                        {lang === 'ru' ? answer_ru : answer_en}</div>)
                }
            </div>

        </div>
    )
}

export default Questions