import React, { FC } from 'react';
import s from './Description.module.css'
import img from '../../../assets/images/HomePage/watch_msg.png'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store/hooks/hooks';

const Description: FC = () => {
    const { t, i18n } = useTranslation()
    const { company } = useAppSelector(state => state.info)
    const lang = i18n.language

    return (
        <section className={s.description_section}>
            <div className={s.container}>
                <div className={s.card}>
                    <div className={s.dark_bg} />
                    <div className={s.leftSide}>
                        <h5 className={s.section_title}>{t("about_us.briefly")}</h5>
                        <h2 className={s.title_description}>{t("about_us.description_title")}</h2>
                        <span className={s.description}>
                            {lang === 'ru' ? company?.description_ru : company?.description_en}
                        </span>
                    </div>

                    <div className={s.rightSide}>
                        <img className={s.rightSideImage} src={img} alt="img" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Description;