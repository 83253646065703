import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchCourseById } from '../../store/slices/coursesSlice';
import Hero from './components/Hero/Hero';
import Description from '../../components/Description/Description';
import Technologies from './components/Technologies/Technologies';
import SkillsYouGain from './components/SkillsYouGain/SkillsYouGain';
import StudyingProcess from './components/StudyingProcess/StudyingProcess';
import Employment from '../../components/Employment/Employment';
import Portfolio from '../../components/Portfolio/Portfolio';
import FAQ from '../../components/FAQ/FAQ';
import Certificate from '../../components/Certificate/Certificate';
import Resume from './components/Resume/Resume';
import TechnologyCallback from './components/TechnologyCallback/TechnologyCallback';
import Mentors from '../../components/Mentors/Mentors';
import { Helmet } from 'react-helmet-async';

const Course: FC = () => {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const { course } = useAppSelector(state => state.courses)

    useEffect(() => {
        dispatch(fetchCourseById(Number(id)))
        window.scrollTo(0, 0)
    }, [id, dispatch])
    return (
        <div>
            <Helmet>
                <link rel="canonical" href={`https://itcbootcamp.com/courses/${id}/${course?.name_en}`} />
                <title>
                    {`${course?.name_en} | ITCbootcamp`}
                </title>
            </Helmet>
            {course && <Hero course={course} />}
            {course && <Description course={course} />}
            {course && <Technologies course={course} />}
            {course && <SkillsYouGain course={course} />}
            <TechnologyCallback />
            <StudyingProcess />
            <Employment />
            <Portfolio />
            <Mentors id={Number(id)} />
            <FAQ course_id={Number(id)} />
            {course && <Resume id={Number(id)} course={course} />}
            <Certificate />
        </div>
    );
};

export default Course;