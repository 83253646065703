import React, { FC } from 'react'
import s from './Portfolio.module.css'
import { StudentWorksData } from '../../store/modules'
import { useTranslation } from 'react-i18next'


interface PortfolioCardsProps {
    item: StudentWorksData
}


const PortfolioCards: FC<PortfolioCardsProps> = ({ item }) => {
    const { i18n } = useTranslation()
    const { image, link, name_en, name_ru, student_name_en, student_name_ru } = item
    const lang = i18n.language
    return (
        <div className={s.card}>

            <a className={s.card_link} href={link} target="_blank" rel="noreferrer">
                <img className={s.project_img} src={`https${image.slice(4)}`} alt={name_en} />
                <h5 className={s.student_name}>{lang === 'ru' ? student_name_ru : student_name_en}</h5>
                <h5
                    title={lang === 'ru' ? name_ru : name_en}
                    className={s.project_name}>
                    {lang === 'ru' ?
                        name_ru.length > 33 ? name_ru.slice(0, 33) + '...' : name_ru
                        : name_en.length > 33 ? name_en.slice(0, 33) + '...' : name_en
                    }
                </h5>
            </a>
        </div>
    )
}

export default PortfolioCards