import React, { FC } from 'react'
import { ResumeData } from '../../../../../store/modules'
import s from './ResumeProjects.module.css'
import { useTranslation } from 'react-i18next'

interface ResumeProjectsProps {
    resume: ResumeData
}

const ResumeProjects: FC<ResumeProjectsProps> = ({ resume }) => {

    const { resume_projects } = resume
    const { i18n } = useTranslation()
    const lang = i18n.language

    return (
        <div className={s.projects_block}>
            {
                resume_projects.map((project, i) => (
                    <div key={i} className={s.project_item}>
                        {
                            lang === 'ru' ? project.name_ru : project.name_en
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default ResumeProjects