import React, { FC } from 'react';
import s from './Footer.module.css'
import { useAppSelector } from '../../store/hooks/hooks';
import { pathLink } from '../../reused';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import visa from '../../assets/images/visa.png'
import mastercard from '../../assets/images/mastercard.png'


const Footer: FC = () => {
    const { t } = useTranslation()
    const { company } = useAppSelector(state => state?.info)

    const year = new Date().getFullYear()
    return (
        <div className={s.footer}>
            <div className={s.container}>
                <div className={s.footer_top_block}>
                    <div className={s.logo_block}>
                        <img className={s.logo} title={company?.name} src={pathLink + company?.logo} alt='logo' />
                    </div>
                    <div className={s.footer_nav}>
                        <Link className={s.links} to='/info_pages/contacts'>
                            {t("footer.contacts")}
                        </Link >
                        <Link className={s.links} to='/info_pages/payment'>
                            {t("footer.payment")}
                        </Link >
                        <Link className={s.links} to='/info_pages/privacy_policy'>
                            {t("footer.privacy_policy")}
                        </Link>
                        <Link className={s.links} to='/info_pages/online_pay'>
                            {t("footer.pay")}
                        </Link>
                    </div>
                </div>

                <div className={s.footer_bottom_block}>
                    <div className={s.devs_info}>
                        © {year} ITC devs.
                        <div className={s.cards_imgs}>
                            <img src={visa} alt="card" />
                            <img src={mastercard} alt="card" />
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Footer;