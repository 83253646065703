import React, { FC } from 'react'
import s from './Portfolio.module.css'
import { t } from 'i18next'
import PortfolioCards from './PortfolioCards'
import { useAppSelector } from '../../store/hooks/hooks'
import { Link } from 'react-router-dom'
import { AnimationOnScroll } from 'react-animation-on-scroll'

const Portfolio: FC = () => {
    const { student_works } = useAppSelector(state => state.info)

    return (
        <>
            {
                student_works.length > 0 &&
                <AnimationOnScroll offset={300} animateOnce={true} animateIn='animate__faster animate__zoomIn'>
                    <section className={s.portfolioSection}>
                        <div className={s.container}>
                            <div className={s.leftSide}>
                                <h5 className={s.section_title}>
                                    {t("home.portfolio.portfolio")}
                                </h5>
                                <h2 className={s.section_title_des}>
                                    {t("home.portfolio.title-description")}
                                </h2>
                                <span className={s.description}>
                                    {t("home.portfolio.description")}
                                </span>

                                <div className={s.actions}>
                                    <Link to={'/list_studets_work'}>
                                        <button className={s.allProjects_btn}>Посмотреть все работы</button>
                                    </Link>
                                </div>
                            </div>
                            <div className={s.rightSide}>
                                <div className={s.leftCards}>
                                    {
                                        student_works?.slice(0, 2)?.map(el => (
                                            <PortfolioCards key={el.id} item={el} />
                                        ))
                                    }
                                </div>

                                <div className={s.rightCards}>
                                    {
                                        student_works?.slice(2, 4).map(el => (
                                            <PortfolioCards key={el.id} item={el} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </AnimationOnScroll>
            }
        </>
    )
}

export default Portfolio