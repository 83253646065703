import React, { FC } from 'react'
import s from './CourseItem.module.css'
import { useTranslation } from 'react-i18next';
import { TechnologiesData } from '../../../store/modules';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { platformAPI } from '../../../axios';
import { toggleRegist } from '../../../store/slices/userSlice';


interface TechnologiesProps {
    technology: TechnologiesData
    course_id: number
}

const Technologies: FC<TechnologiesProps> = ({ technology, course_id }) => {
    const { name_en, name_ru, logo, id, price } = technology
    const { t, i18n } = useTranslation()
    const { token } = useAppSelector(state => state.user)
    const { list } = useAppSelector(state => state.list_courses)
    const dispatch = useAppDispatch()
    const lang = i18n.language


    const addNewTechnology = () => {
        if (token) {
            platformAPI.getPyamentLinkByTechnology({
                currency: 'KGS',
                amount: `${price}`,
                description: name_ru,
            })
                .then(res => {
                    const data = {
                        order_id: res.data.order_id,
                        pay_name: 'payTech',
                        booking_course_id: id,
                        payment_id: res.data.payment_id,
                    }
                    localStorage.setItem('payment', JSON.stringify(data))
                    window.location.href = res.data.url
                })
                .catch(err => console.log(err))
        } else {
            dispatch(toggleRegist(true))
        }
    }


    return (
        <div className={s.course_technologies}>
            <div className={s.icon_box}>
                <img className={s.icon} src={`https${logo.slice(4)}`} alt={lang === 'ru' ? name_ru : name_en} />
            </div>
            <div className={s.technologies_detail}>
                <h3>
                    {lang === 'ru' ? name_ru : name_en}
                </h3>
                <span>

                </span>
                <Link to={`/technologie/${id}/${name_en}/?course_id=${course_id}`}>
                    <button className={s.detailBtn}>
                        {t("allCourses.detailBtn")}
                    </button>
                </Link>
            </div>
            {
                list.find(el => el.technology_id === id) ?
                    <div className={s.course_tech_actions}>
                        <p className={s.already_have}>{t("allCourses.already")}</p>
                    </div>

                    :
                    <div className={s.course_tech_actions}>
                        <button onClick={addNewTechnology} className={s.purchaseCourse}>
                            {t("allCourses.purchaseCourse")} {price} KGS
                        </button>
                        {/* <p className={s.price_block}>
                            {t("booking.price")}
                            <span className={s.price}>{price} KGS</span>
                        </p> */}
                    </div>
            }

        </div>
    )
}

export default Technologies