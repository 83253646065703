import React, { FC, useState } from 'react'
import s from './Questions.module.css'
import arrow from '../../../../assets/images/arrowDown.png'
import { FAQData } from '../../../../store/modules'
import { useTranslation } from 'react-i18next';

interface QuestionsProps {
    questions: FAQData
}

const Questions: FC<QuestionsProps> = ({ questions }) => {

    const { question_ru, question_en, answer_ru, answer_en, id } = questions
    const { i18n } = useTranslation()
    const lang = i18n.language
    const [openId, setOpenId] = useState<null | number>(null)

    const toggleAnswer = (id: number) => {
        setOpenId(openId === id ? null : id)
    }



    return (
        <div className={s.question_container}>
            <div onClick={() => toggleAnswer(id)} className={s.question}>
                <p>
                    {lang === 'ru' ? question_ru : question_en}
                </p>
                <img className={openId ? `${s.arrow_up}` : `${s.arrow_down}`} src={arrow} alt="arrow" />
            </div>
            {
                openId === id && (<div
                    className={`${openId === id ? `${s.answerOpen} animate__animated  animate__bounceInLeft animate__faster` : `${s.answer}`}`}>
                    {lang === 'ru' ? answer_ru : answer_en}</div>)
            }

        </div>
    )
}

export default Questions