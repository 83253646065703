import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MentorsData } from '../../../store/modules';
import s from '../Mentors.module.css'
import MentorModal from '../MentorModal/MentorModal';

interface MentorCardProps {
    item: MentorsData
}

const MentorCard: FC<MentorCardProps> = ({ item }) => {
    const [showMentor, setShowMentor] = useState(false)
    const { t, i18n } = useTranslation()
    const lang = i18n.language
    const {
        avatar,
        full_name_en,
        full_name_ru,
        work_place,
        city } = item

    return (
        <>
            <div onClick={() => setShowMentor(true)} className={s.mentor_card}>
                <img className={s.mentor_avatar} src={`https${avatar.slice(4)}`} alt={full_name_en} />
                <h3 className={s.mentor_name}>{lang === 'ru' ? full_name_ru : full_name_en}</h3>
                <h5 className={s.work_place}>{work_place}</h5>
                <h5 className={s.mentor_city}>{city}</h5>
            </div>
            {showMentor && <MentorModal item={item} showMentor={showMentor} setShowMentor={setShowMentor} />}
        </>
    );
};

export default MentorCard;