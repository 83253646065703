import React, { FC } from 'react'
import s from './Employment.module.css'
import EmployeeCard from './EmployeeCard'
import { useAppSelector } from '../../store/hooks/hooks'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { useTranslation } from 'react-i18next'


const Employment: FC = () => {
    const { coop_companies } = useAppSelector(state => state.info)
    const { t } = useTranslation()

    return (
        <>
            {
                coop_companies.length > 0 &&
                <AnimationOnScroll offset={300} animateOnce={true} animateIn='animate__faster animate__fadeInLeft'>
                    <section className={s.employment_section}>
                        <div className={s.container}>
                            <div className={s.textContent}>
                                <h5 className={s.section_title}>
                                    {t("home.employment.employment")}
                                </h5>
                                <h2 className={s.title_description}>
                                    {t("home.employment.title-description")}
                                </h2>
                                <p className={s.description}>
                                    {t("home.employment.description")}
                                </p>
                            </div>

                            <div className={s.employeesCards}>
                                {
                                    coop_companies.map(el => (
                                        <EmployeeCard key={el.id} item={el} />
                                    ))
                                }
                            </div>
                        </div>
                    </section>
                </AnimationOnScroll>
            }
        </>
    )
}

export default Employment