import React, { FC, useEffect } from 'react'
import s from './Feedback.module.css'
import FeedbackCard from './FeedbackCard'
// asset imports
import arrowLeft from '../../assets/images/HomePage/arrowActiveLeft.png'
import arrowRight from '../../assets/images/HomePage/arrowActiveRight.png'
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Keyboard, Navigation } from 'swiper';
// swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import './swiperStyle.css'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks'
import { fetchByReviews, toggleFeedbackModal } from '../../store/slices/infoSlice'
// import { toggleRegist } from '../../../../store/slices/userSlice'
import NewFeedbackModal from '../NewFeedbackModal/NewFeedbackModal'
import { toggleRegist } from '../../store/slices/userSlice'
import SuccessFeedback from '../SuccessFeedback/SuccessFeedback'
import { useTranslation } from 'react-i18next'

const SliderButtonsHandler = () => {

    return (
        <div className={s.arrows}>
            <button
                className='review-swiper-button-prev'>
                <img src={arrowLeft} alt="arrow" />
            </button>
            <button
                className='review-swiper-button-next'>
                <img src={arrowRight} alt="arrow" />
            </button>
        </div>
    )
}


const Feedback: FC = () => {
    const { reviews, feedback_modal, success_feedback } = useAppSelector(state => state.info)
    const { token } = useAppSelector(state => state.user)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const addFeedback = () => {
        if (token) {
            dispatch(toggleFeedbackModal(true))
        } else {
            dispatch(toggleRegist(true))
        }
    }

    useEffect(() => {
        dispatch(fetchByReviews())
    }, [dispatch])

    return (
        <section className={s.feedback_section}>
            <div className={s.container}>
                <h5 className={s.section_title}>
                    {t("home.feedbacks.feedbacks")}
                </h5>
                <h2 className={s.title_description}>
                    {t("home.feedbacks.title-descriptions")}
                </h2>
                {
                    reviews.length > 0 ?
                        <Swiper
                            direction='vertical'
                            effect={'cards'}
                            keyboard
                            grabCursor={true}
                            navigation={{
                                nextEl: ".review-swiper-button-next",
                                prevEl: ".review-swiper-button-prev",
                            }}
                            modules={[EffectCards, Navigation, Keyboard]}
                            className="feedbackSwiper"
                        >
                            {
                                reviews?.map(el => (
                                    <SwiperSlide key={el.id} className='feedbackSlider'>
                                        <FeedbackCard info={el} />
                                    </SwiperSlide>
                                ))
                            }
                            <SliderButtonsHandler />
                        </Swiper>
                        :
                        <h2 className={s.section_title}>{t("course.feedback.empty")}</h2>
                }


                <button onClick={addFeedback} className={s.leaveAfeedback_btn}>
                    {t("home.feedbacks.leave_feedback_btn")}
                </button>
                {feedback_modal && <NewFeedbackModal />}
                {success_feedback && <SuccessFeedback />}
            </div>
        </section>
    )
}

export default Feedback