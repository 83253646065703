import React, { FC } from 'react'
import { ResumeData } from '../../../../../store/modules'
import s from './ResumeSkills.module.css'

interface ResumeSkillsProps {
    resume: ResumeData
}

const ResumeSkills: FC<ResumeSkillsProps> = ({ resume }) => {

    const { resume_technologies } = resume
    return (
        <ul className={s.skill_block}>
            {
                resume_technologies.map((stack, i) => (
                    <li key={i} className={s.skill_item}>
                        {stack.name}
                    </li>
                ))
            }
        </ul>
    )
}

export default ResumeSkills