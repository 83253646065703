import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';
import s from './Booking.module.css'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchByBookingCourses } from '../../store/slices/coursesSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import backImg from '../../assets/images/goBack.png'
import { useTranslation } from 'react-i18next';
import BookingCard from './BookingCard/BookingCard';
import { toggleRegist } from '../../store/slices/userSlice';
import { Helmet } from 'react-helmet-async';
import { platformAPI } from '../../axios';

const Booking: FC = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { booking } = useAppSelector(state => state.courses)
    const { token } = useAppSelector(state => state.user)
    const [course, setCourse] = useState<number>(0)
    const [error, setError] = useState('')
    const [currentCourse, setCurrentCourse] = useState({
        amount: '',
        name: '',
        city: ''
    })
    const { t, i18n } = useTranslation()
    const lang = i18n.language

    const { cource_name_en, cource_name_ru } = (booking && booking[0]) || ''

    const goBack = () => {
        navigate(-1)
    }


    const addNewBooking = () => {
        if (token && course && currentCourse.city) {
            platformAPI.getPaymentLink({
                amount: currentCourse.amount,
                currency: currentCourse.city === 'Алматы' ? "KZT" : "KGS",
                description: currentCourse.name,
            })
                .then(res => {
                    setError('')
                    const data = {
                        order_id: res.data.order_id,
                        pay_name: 'booking',
                        city: currentCourse.city,
                        booking_course_id: course,
                        payment_id: res.data.payment_id,
                    }
                    localStorage.setItem('payment', JSON.stringify(data))
                    window.location.href = res.data.url
                })
                .catch(err => {
                    setError(t("booking.req_err"))
                    console.log(err);

                })

        } else if (!course) {
            setError(t("booking.error"))
        } else {
            dispatch(toggleRegist(true))
        }
    }

    const toggleCity: ChangeEventHandler<HTMLSelectElement> = (e) => {
        setCurrentCourse({ ...currentCourse, city: e.target.value })
        dispatch(fetchByBookingCourses({ id: Number(id), city: e.target.value }))
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [id, dispatch])

    return (
        <div className={s.container}>
            <Helmet>
                <link rel="canonical" href={`https://itcbootcamp.com/booking_course/${id}`} />
                <title>
                    {lang === 'ru' ? `Бронь курса | ITCbootcamp` : `Booking a course | ITCbootcamp`}
                </title>
            </Helmet>
            <div className={s.title_block}>
                <img className={s.back_img} onClick={goBack} src={backImg} alt="back img" />
                <h2 className={s.title}>{t("booking.title")}</h2>
            </div>

            <div className={s.city_block}>
                <h3 className={s.cities_title}>{t("cities.title")}:</h3>
                <select defaultValue={t("cities.select")} onChange={toggleCity} className={s.select_city}>
                    <option className={s.first} value={t("cities.select")} disabled>{t("cities.select")}:</option>
                    <option value={"Алматы"}>{t("cities.city1")}</option>
                    <option value={"Бишкек"}>{t("cities.city2")}</option>
                    {/* <option value={t("cities.city3")}>{t("cities.city3")}</option> */}
                    <option value={"Ош"}>{t("cities.city4")}</option>
                    {/* <option value={"Джалал-Абад"}>{t("cities.city5")}</option> */}
                </select>
            </div>

            {
                currentCourse.city && booking.length > 0 ?
                    <>
                        <div className={s.subtitle_block}>
                            <h4>{t("booking.course")}</h4>
                            <h2>{lang === 'ru' ? cource_name_ru : cource_name_en}</h2>
                        </div>

                        <div className={s.choose_wrapper}>
                            <div className={s.choose_container}>
                                <div className={`${s.online_block} ${s.courses}`}>
                                    <h3>{t("booking.learning_1")}</h3>
                                    {
                                        booking.length > 0 && booking?.map(el => el.type_cource === 'Online' &&
                                            <BookingCard
                                                setCurrentCourse={setCurrentCourse}
                                                currentCourse={currentCourse}
                                                setCourse={setCourse}
                                                key={el.id} item={el} />)
                                    }
                                </div>
                                <div className={`${s.offline_block} ${s.courses}`}>
                                    <h3>{t("booking.learning_2")}</h3>
                                    {
                                        booking.length > 0 && booking?.map(el => el.type_cource === 'Offline' &&
                                            <BookingCard
                                                setCurrentCourse={setCurrentCourse}
                                                currentCourse={currentCourse}
                                                setCourse={setCourse}
                                                key={el.id} item={el} />)
                                    }
                                </div>
                            </div>
                            <button onClick={addNewBooking} className={s.booking_btn}>{t("booking.book")}</button>
                            {error && <span className={s.error}>{error}</span>}
                        </div>
                    </>
                    :
                    currentCourse.city && booking.length === 0
                        ?
                        <p className={s.empty}>
                            {t("booking.empty")}
                        </p>
                        : null
            }

        </div>
    );
};

export default Booking;