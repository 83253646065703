import React, { FC } from 'react'
import s from './Courses.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CourseData } from '../../../../store/modules'

interface CoursesCardsProps {
    course: CourseData
}
const CoursesCards: FC<CoursesCardsProps> = ({ course }) => {
    const { name_en, name_ru, description_en, description_ru, logo, id } = course
    const { t, i18n } = useTranslation()
    const lang = i18n.language

    return (
        <div className={s.card}>
            <div className={s.topBlock}>
                <div className={s.topLeft}>
                    <div className={s.card_icons}>
                        <div className={s.first_icon}>
                            <img className={s.icon} src={`https${logo.slice(4)}`} alt={lang === 'ru' ? name_ru : name_en} />
                        </div>
                    </div>
                    <h2 className={s.courseName}>{
                        name_ru
                    }</h2>
                </div>
                <div className={s.topRight}>
                    <span className={s.description}>
                        {lang === 'ru' ? description_ru.length > 90 ? description_ru.slice(0, 90) + '...' : description_ru : description_en.length > 90 ? description_en.slice(0, 90) + '...' : description_en}
                    </span>
                </div>

            </div>
            <Link to={`/courses/${id}/${name_en}`}>
                <button className={s.detailBtn}>
                    {t("home.courses-section.detail")}
                </button>
            </Link>
        </div>
    )
}

export default CoursesCards