import React, { FC, useEffect } from 'react';
import s from './ListMyCourses.module.css'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByListVideosByTechnologie } from '../../store/slices/userCoursesSlice';
import { useNavigate, useParams } from 'react-router-dom';
import goBack from '../../assets/images/goBack.png'
import { useTranslation } from 'react-i18next';
import ListCard from './ListCard/ListCard';
import { Helmet } from 'react-helmet-async';

const ListMyCourses: FC = () => {
    const { id_technologie } = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { token } = useAppSelector(state => state.user)
    const { list_videos } = useAppSelector(state => state.list_courses)
    const { i18n } = useTranslation()
    const lang = i18n.language

    useEffect(() => {
        if (id_technologie) {
            dispatch(fetchByListVideosByTechnologie({ token, id_technologie }))
            window.scrollTo(0, 0)
        }
    }, [id_technologie, dispatch])
    return (
        <section className={s.container}>
            <Helmet>
                <title>
                    {lang === 'ru' ? `${list_videos?.technology_name_en} список видео | ITCbootcamp` : `${list_videos?.technology_name_en} list video | ITCbootcamp`}
                </title>
            </Helmet>
            <div className={s.goBack_title_block}>
                <img onClick={() => navigate(-1)} src={goBack} alt="goBack" />
                <h2 className={s.title}>{lang == 'ru' ? list_videos?.technology_name_ru : list_videos?.technology_name_en}</h2>
            </div>

            <div className={s.videos_wrapper}>
                {
                    list_videos?.videos &&
                    list_videos?.videos.map(el => <ListCard key={el.id} item={el} id_technologie={id_technologie || ''} />)
                }
            </div>
        </section>
    );
};

export default ListMyCourses;