import React, { Dispatch, FC, MouseEventHandler, SetStateAction } from 'react';
import s from './ProfileBlock.module.css'
import arrows from '../../assets/images/profile-arrows.png'
import { useAppSelector } from '../../store/hooks/hooks';
import SettingsModal from './SettingsModal/SettingsModal';
import { pathLink } from '../../reused';

interface ProfileBlockProps {
    showSettings: boolean
    setShowSettings: Dispatch<SetStateAction<boolean>>
}

const ProfileBlock: FC<ProfileBlockProps> = ({ setShowSettings, showSettings }) => {
    const { avatar, username } = useAppSelector(state => state.user.user_profile)


    const toggleModal: MouseEventHandler<HTMLDivElement> = (e) => {
        setShowSettings(!showSettings)
    }

    return (
        <div className={`${s.wrapper} toggle`}>
            <div onClick={toggleModal} className={`${s.container} toggle`}>
                <img className={`${s.avatar} toggle`} src={avatar && avatar.startsWith('http') ? `https${avatar.slice(4)}` : pathLink + avatar} alt="avatar" />
                <h4 className={`${s.userName} toggle`}>{username.length > 16 ? username.slice(0, 16) + '...' : username}</h4>
                <img className={`${s.arrows} toggle`} src={arrows} alt="arrows" />
            </div>
            {showSettings && <SettingsModal setShowSettings={setShowSettings} />}
        </div>
    );
};

export default ProfileBlock;