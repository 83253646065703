import React, { FC, useRef, useState } from 'react';
import s from './SocialLinks.module.css'
import disc from '../../assets/images/Discord.png'
import telegram from '../../assets/images/Telegram.png'
import inst from '../../assets/images/Instagram.png'
import wa from '../../assets/images/Whatsapp.png'
import chatsIcon from '../../assets/images/Chatbox.png'
import closeIcon from '../../assets/images/close.png'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { toggleCitiesModal } from '../../store/slices/infoSlice';
import CitiesModal from './CitiesModal/CitiesModal';

const SocialLinks: FC = () => {
    const { company, cities_modal } = useAppSelector(state => state.info)
    const [chatbox, setChatBox] = useState(false)
    const linksBlock = useRef<HTMLDivElement | null>(null)
    const [chooseName, setChooseName] = useState('')
    const dispatch = useAppDispatch()

    const toggleClass = () => {
        if (linksBlock.current && linksBlock.current.classList[2] === 'links_block') {
            linksBlock.current.classList.add('animate__zoomOutUp')
        }

        chatbox && linksBlock.current ?
            linksBlock.current.classList.replace('animate__zoomInDown', 'animate__zoomOutUp')
            :
            linksBlock.current && linksBlock.current.classList.replace('animate__zoomOutUp', 'animate__zoomInDown')
    }

    const toggleVisible = () => {
        setChatBox(!chatbox)
        toggleClass()
    }

    const openCities = (name: string) => {
        setChooseName(name)
        dispatch(toggleCitiesModal(true))
    }

    return (
        <>
            <div className='chatbox_block'>
                <img
                    className='chatbox'
                    src={chatbox ? closeIcon : chatsIcon}
                    alt="chatbox"
                    onClick={toggleVisible}
                />
                <div className={`${s.links_wrapper} animate__animated links_block animate__faster`}
                    style={{ display: chatbox ? "flex" : 'none' }} ref={linksBlock}>
                    <a rel="noreferrer" target={'_blank'} href={company?.discord}>
                        <img className={s.social_imgs} src={disc} alt="discord" />
                    </a>
                    <span onClick={() => openCities('telegram')}>
                        <img className={s.social_imgs} src={telegram} alt="telegram" />
                    </span>
                    <span onClick={() => openCities('instagram')}>
                        <img className={s.social_imgs} src={inst} alt="instagram" />
                    </span>
                    <span onClick={() => openCities('whatsapp')}>
                        <img className={s.social_imgs} src={wa} alt="whatsapp" />
                    </span>
                </div>
            </div>
            {chooseName && cities_modal ?
                <CitiesModal
                    setChooseName={setChooseName}
                    cities_modal={cities_modal}
                    chooseName={chooseName} />
                : null}
        </>

    );
};

export default SocialLinks;