import React, { FC } from 'react'
import s from './Contacts.module.css'
import { CompanyData } from '../../store/modules'
import instagramIcon from '../../assets/images/Instagram.png'
import telegramIcon from '../../assets/images/Telegram.png'
import discordIcon from '../../assets/images/Discord.png'
import whatsappIcon from '../../assets/images/Whatsapp.png'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

interface ContactsProps {
    company: CompanyData
}

const Contacts: FC<ContactsProps> = ({ company }) => {
    const { discord, instagram_bishkek, phone_number, telegram_bishkek, whatsapp_bishkek, } = company
    const { t, i18n } = useTranslation()
    const lang = i18n.language



    return (
        <div className={`${s.contacts} animate__animated animate__fadeIn animate__faster`}>
            <Helmet>
                <title>
                    {lang === 'ru' ? `Наши контакты | ITCbootcamp` : `Our contacts | ITCbootcamp`}
                </title>
            </Helmet>
            <div className={s.container}>
                <h5 className={s.contact_title}>
                    {t("contacts.contacts")}
                </h5>
                <div className={s.contact_list}>
                    <div className={s.email}>
                        <img src={discordIcon} alt="email" />
                        <a href="mailto:itcbootcamp32@gmail.com">Email</a>
                    </div>
                    <div className={s.social_block}>
                        <div className={s.social_item}>
                            <img src={instagramIcon} alt="instagram" />
                            <a target={'_blank'} rel="noreferrer" href={instagram_bishkek || ''}>Instagram</a>
                        </div>
                        <div className={s.social_item}>
                            <img src={telegramIcon} alt="Telegram" />
                            <a target={'_blank'} rel="noreferrer" href={telegram_bishkek || ''}>Telegram</a>
                        </div>
                        <div className={s.social_item}>
                            <img src={discordIcon} alt="Discord" />
                            <a target={'_blank'} rel="noreferrer" href={discord}>Discord</a>
                        </div>
                        <div className={s.social_item}>
                            <img src={whatsappIcon} alt="WhatsApp" />
                            <a target={'_blank'} rel="noreferrer" href={whatsapp_bishkek || ''}>WhatsApp</a>
                        </div>
                    </div>
                </div>

                <div className={s.physical_address}>
                    <p>
                        {t("contacts.address")}
                    </p>
                    <a target={'_blank'} rel='noreferrer' href="https://go.2gis.com/jhpayf">
                        {t("contacts.address_text")}
                    </a>
                </div>
                <div className={s.telephone_number}>
                    <p>
                        {t("contacts.phone_number")}
                    </p>
                    <a target={'_blank'} rel="noreferrer" href={`tel:${phone_number}`}>{phone_number}</a>
                </div>

                <div className={s.telephone_number}>
                    <p>
                        {t("contacts.bank_info")}
                    </p>
                    <span>
                        {t("contacts.bank_text")}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Contacts