import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { loginFetchByToken, toggleChangePass, toggleRegist, toggleLogin, writeName, writePass } from '../../../store/slices/userSlice';
import s from './Login.module.css'

const Login: FC = () => {
    const { t } = useTranslation()
    const [error, setError] = useState('')
    const [showPass, setShowPass] = useState(false)
    const { user_name, login_modal, user_pass, error_message } = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()

    const toggleForm: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        if (user_name.trim().length === 0) {
            setError(t("auth.name_err"))
        } else {
            setError('')
            dispatch(loginFetchByToken({ username: user_name, password: user_pass }))
        }
    }

    const toggleModals = () => {
        dispatch(toggleLogin(false))
        dispatch(toggleRegist(true))
    }

    useEffect(() => {
        if (login_modal) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = (e) => {
                dispatch(toggleLogin(false));
            };
        }
        return () => {
            if (!login_modal) window.history.back();
            window.onpopstate = () => { };
        };

    }, [login_modal, dispatch])

    return (
        <div
            onClick={() => dispatch(toggleLogin(false))}
            className={`${s.login_wrapper} animate__animated animate__fadeIn animate__faster`}>
            <div onClick={(e) => e.stopPropagation()} className={s.container}>
                <h1 className={s.title}>{t("auth.login_title")}</h1>
                <form onSubmit={toggleForm} className={s.inputs_block}>
                    <input
                        onChange={(e) => dispatch(writeName(e.target.value))}
                        value={user_name}
                        className={`${s.inputs} animate__animated animate__flipInX`}
                        type="text" placeholder={t("auth.name")} />
                    <input
                        onChange={(e) => dispatch(writePass(e.target.value))}
                        value={user_pass}
                        className={`${s.inputs} animate__animated animate__flipInX`}
                        type={showPass ? 'text' : 'password'} placeholder={t("auth.pass")} />
                    <button className={s.continue_btn}>{t("auth.login_btn")}</button>
                    {error && <span className={s.error}>{error}</span>}
                    {error_message && <span className={s.error}>{t("auth.login_err")}</span>}
                    <label className={s.show_pass}>
                        <input onChange={() => setShowPass(!showPass)} id="demo_opt_1" className={s.show_pass_inp} type="checkbox" />
                        <label htmlFor="demo_opt_1">{t("auth.show_pass")}</label>
                    </label>
                </form>
                <div className={s.bottom_info_block}>
                    <p className={s.reg_text}>{t("auth.redirect_register")} <span onClick={toggleModals} className={s.redirect}>{t("auth.link_register")}</span></p>
                    <span onClick={() => dispatch(toggleChangePass(true))} className={s.forgot_btn}>{t("auth.forgot_pass")}</span>
                </div>
            </div>
        </div>
    );
};

export default Login;