import React, { FC } from 'react'
import s from './PrivacyPolicy.module.css'
import { CompanyData } from '../../store/modules';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

interface PrivacyPolicyProps {
    company: CompanyData
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({ company }) => {

    const { privacy_policy_ru, privacy_policy_en } = company
    const { t, i18n } = useTranslation()
    const lang = i18n.language

    return (
        <div className={`${s.privacy_policy} animate__animated animate__fadeIn animate__faster`}>
            <Helmet>
                <title>
                    {lang === 'ru' ? 'Политика | ITCbootcamp' : 'Policy | ITCbootcamp'}
                </title>
            </Helmet>
            <div className={s.container}>
                <h2 className={s.privacy_title}>
                    {t("privacy_policy.privacy_policy")}
                </h2>
                <p className={s.text}>
                    {lang === 'ru' ? privacy_policy_ru : privacy_policy_en}
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy