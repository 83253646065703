import React, { FC } from 'react'
import { CompanyData } from '../../store/modules'
import s from './ConditionOfUse.module.css'
import { useTranslation } from 'react-i18next'

interface ConditionOfUseProps {
    company: CompanyData
}

const ConditionOfUse: FC<ConditionOfUseProps> = ({ company }) => {
    // Now name Return
    const { terms_use_en, terms_use_ru } = company
    const { t, i18n } = useTranslation()
    const lang = i18n.language

    return (
        <div className={`${s.condition} animate__animated animate__fadeIn animate__faster`}>
            <div className={s.container}>
                <h2 className={s.condition_title}>
                    {t("footer.pay")}
                </h2>
                <p className={s.condition_content}>
                    {lang === 'ru' ? terms_use_ru : terms_use_en}
                </p>
            </div>
        </div>
    )
}

export default ConditionOfUse