import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import s from './ChangePassModal.module.css'
import closeIcon from '../../../assets/images/close.png'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { toggleChangePass, fetchByChangePass } from '../../../store/slices/userSlice';
import { validateEmail } from '../../../reused';

const ChangePassModal: FC = () => {
    const { t } = useTranslation()
    const { error_message, success_change_pass } = useAppSelector(state => state.user)
    const [value, setValue] = useState('')
    const [error, setError] = useState('')
    const dispatch = useAppDispatch()

    const toggleForm: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        if (!validateEmail(value)) {
            setError('')
            dispatch(fetchByChangePass({ email: value }))
        } else {
            setError(t("auth.email_error"))
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) dispatch(toggleChangePass(false))
        })

        return document.removeEventListener('keydown', () => { })
    }, [dispatch])

    return (
        <div className={`${s.wrapper} animate__animated animate__fadeIn animate__faster`}>
            <div className={s.container}>
                {
                    success_change_pass === "success"
                        ?
                        <div className={s.success_block}>
                            <h1 className={s.success_title}>{t("auth.success_message")}</h1>
                        </div>
                        :
                        <>
                            <div className={s.title_top_block}>
                                <h2 className={s.title}>{t("auth.change_pass_title")}</h2>
                                <img onClick={() => dispatch(toggleChangePass(false))} className={s.close} src={closeIcon} alt="close" />
                            </div>
                            <form onSubmit={toggleForm} className={s.change_form}>
                                <input
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    type="email" className={s.user_email} placeholder="Email" />
                                <button className={s.continue_btn}>{t("auth.verify_btn")}</button>
                            </form>
                            {error && <span className={s.error}>{error}</span>}
                            {error_message && <span className={s.error}>{t("auth.change_pass_err")}</span>}
                        </>

                }

            </div>
        </div>
    );
};

export default ChangePassModal;