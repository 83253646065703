import React, { FC, useEffect } from 'react'
import s from './FAQ.module.css'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks'
import { fetchByFAQ } from '../../store/slices/coursesSlice'
import { useTranslation } from 'react-i18next'
import Questions from './Questions/Questions'

interface FAQProps {
    course_id: number
}

const FAQ: FC<FAQProps> = ({ course_id }) => {
    const dispatch = useAppDispatch()
    const { questions } = useAppSelector(state => state.courses)
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(fetchByFAQ(course_id))
        window.scrollTo(0, 0)
    }, [course_id, dispatch])
    return (
        <div className={s.faq_section}>
            <div className={s.container}>
                <h5 className={s.section_title}>
                    {t("allCourses.faq")}
                </h5>
                <h3 className={s.title_des}>
                    {t("allCourses.faq_description")}
                </h3>

                <span className={s.description}>
                    {t("allCourses.description")}
                </span>

                <div className={s.faq_cards}>
                    {
                        questions && questions.map(question => (
                            <Questions
                                key={question.id}
                                questions={question} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default FAQ