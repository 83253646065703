import React, { FC, useEffect, useState } from 'react';
import s from './Video.module.css'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByGetListFavorites, fetchByListVideosByTechnologie, fetchToggleUserFavorites, fetchVideoById } from '../../store/slices/userCoursesSlice';
import { useNavigate, useParams } from 'react-router-dom';
import goBack from '../../assets/images/goBack.png'
import { useTranslation } from 'react-i18next';
import "/node_modules/video-react/dist/video-react.css";
import {
    Player,
    ControlBar,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    LoadingSpinner,
    BigPlayButton,
} from 'video-react';
import { videoLink } from '../../reused';
import ListVideos from './ListVideos/ListVideos';
import star from '../../assets/images/star-transparent.png'
import fullStar from '../../assets/images/star.png'
import { Helmet } from 'react-helmet-async';



const Video: FC = () => {
    const { video_id, technologie_id } = useParams()
    const [fullDesc, setFullDesc] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { token } = useAppSelector(state => state.user)
    const { video, whishlist, btn_disabled } = useAppSelector(state => state.list_courses)
    const { t, i18n } = useTranslation()
    const lang = i18n.language

    const toggleFavorites = () => {
        if (video_id) {
            dispatch(fetchToggleUserFavorites({ token, video_id }))
        }
    }

    useEffect(() => {
        if (video_id) {
            dispatch(fetchVideoById({ token, video_id }))
            window.scrollTo(0, 0)
        }
    }, [video_id, dispatch])

    useEffect(() => {
        if (technologie_id) {
            dispatch(fetchByListVideosByTechnologie({ token, id_technologie: technologie_id }))
            window.scrollTo(0, 0)
        }
    }, [technologie_id, dispatch])

    useEffect(() => {
        dispatch(fetchByGetListFavorites(token))
    }, [dispatch])

    return (
        <div className={s.container}>
            <Helmet>
                <title>
                    {lang === 'ru' ? `${video?.name_ru} - ITCbootcamp` : `${video?.name_en} - ITCbootcamp`}
                </title>
            </Helmet>
            <div className={s.goBack_title_block}>
                <img onClick={() => navigate(-1)} src={goBack} alt="goBack" />
                <h2 className={s.title}>{lang == 'ru' ? video?.name_ru : video?.name_en}</h2>
            </div>

            <div className={s.video_block}>
                <div className={s.video_wrapper}>
                    <Player
                        aspectRatio='auto'
                        playsInline
                        src={`${videoLink}${video?.id}/`}
                        poster={`https${video?.preview.slice(4)}`}>
                        <ControlBar>
                            <CurrentTimeDisplay order={4.1} />
                            <TimeDivider order={4.2} />
                            <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.5, 0.25]} />
                            <VolumeMenuButton />
                        </ControlBar>
                        <LoadingSpinner />
                        <BigPlayButton className={`${s.playBtn}`} position='center' />
                    </Player>
                </div>
                <div className={s.rigth_side}>
                    <ListVideos video_id={video_id || ''} technologie_id={technologie_id || ''} />
                    <button
                        disabled={btn_disabled}
                        onClick={toggleFavorites}
                        className={s.add_in_whishlist}>
                        {
                            whishlist.length > 0 && whishlist.find(el => `${el.video}` === video_id)
                                ?
                                <>
                                    <img src={fullStar} alt="star" />
                                    {t("video.from_whishlist")}
                                </>
                                :
                                <>
                                    <img src={star} alt="star" />
                                    {t("video.in_whishlist")}
                                </>
                        }

                    </button>
                </div>
            </div>

            <div className={s.info_wrapper}>
                <div className={s.description_block}>
                    <h3 className={s.info_title}>{t("video.info_title")}</h3>
                    <div onClick={() => setFullDesc(true)} className={s.description_wrapper}>
                        {
                            fullDesc ?
                                <p className={`${s.description} animate__animated animate__fadeIn`}
                                    dangerouslySetInnerHTML={{
                                        __html: lang === 'ru' ?
                                            video?.description_ru || ''
                                            :
                                            video?.description_en || ''
                                    }}></p>
                                :
                                <p className={s.description}
                                    dangerouslySetInnerHTML={{
                                        __html: lang === 'ru' ?
                                            video?.description_ru.slice(0, 500) + '...' || ''
                                            :
                                            video?.description_en.slice(0, 500) + '...' || ''
                                    }}>
                                </p>

                        }
                        <span
                            onClick={(e) => {
                                e.stopPropagation()
                                setFullDesc(!fullDesc)
                            }}
                            className={s.more_btn}>
                            {fullDesc ? t("video.alt_more") : t("video.more_btn")}
                        </span>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Video;