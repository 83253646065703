import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../store/hooks/hooks'
import Contacts from '../../Pages/Contacts/Contacts'
import PrivacyPolicy from '../../Pages/PrivacyPolicy/PrivacyPolicy'
import ConditionOfUse from '../../Pages/ConditionOfUse/ConditionOfUse'
import Payment from '../../Pages/Payment/Payment'
import { Helmet } from 'react-helmet-async'


const InfoPages: FC = () => {
    const { page_name } = useParams()
    const { company } = useAppSelector(state => state?.info)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [page_name])

    return (

        <div className='info_pages'>
            <Helmet>
                <link rel="canonical" href={`https://itcbootcamp.com/info_pages/${page_name}`} />
                <title>
                    {page_name?.toUpperCase()} | ITCBootcamp.com
                </title>
            </Helmet>
            {
                page_name === 'contacts' &&
                    company !== null ? <Contacts company={company} /> : null
            }
            {
                page_name === 'payment' &&
                <Payment />
            }
            {
                page_name === 'privacy_policy' &&

                    company !== null ? <PrivacyPolicy company={company} /> : null
            }
            {
                page_name === 'online_pay' &&
                    company !== null ? <ConditionOfUse company={company} /> : null
            }

        </div>
    )
}

export default InfoPages