import React, { FC, useEffect } from 'react'
import s from './FAQ.module.css'
import { t } from 'i18next'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks'
import { fetchByCompanyFA } from '../../../store/slices/infoSlice'
import Questions from './Quetions/Questions'


const FAQ: FC = () => {
    const { company_aq } = useAppSelector(state => state.info)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchByCompanyFA())
    }, [dispatch])
    return (
        <div className={s.faq_section}>
            <div className={s.container}>
                <h5 className={s.section_title}>
                    {t("allCourses.faq")}
                </h5>
                <h3 className={s.title_des}>
                    {t("allCourses.faq_description")}
                </h3>

                <span className={s.description}>
                    {t("allCourses.description")}
                </span>

                <div className={s.faq_card}>
                    {
                        company_aq && company_aq.map(question => (
                            <Questions
                                key={question.id}
                                questions={question} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default FAQ