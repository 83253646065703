import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { platformAPI } from '../../axios'
import { CompanyData, CoopCompaniesData, FAQData, NewCallBackData, NewReviewData, ReviewData, StudentWorksData } from '../modules'

type InfoState = {
    company: CompanyData | null
    company_name: string
    reviews: ReviewData[]
    feedback_modal: boolean
    cities_modal: boolean
    success_feedback: boolean
    success_callback: boolean
    error_message: boolean
    student_works: StudentWorksData[]
    coop_companies: CoopCompaniesData[]
    company_aq: FAQData[]
}

const initialState: InfoState = {
    company: null,
    company_name: '',
    reviews: [],
    feedback_modal: false,
    cities_modal: false,
    success_feedback: false,
    success_callback: false,
    error_message: false,
    student_works: [],
    coop_companies: [],
    company_aq: [],
}

export const fetchCompanyInfo = createAsyncThunk<CompanyData, void, { rejectValue: string }>(
    'user/fetchCompanyInfo',
    async (_, { rejectWithValue }) => {
        const res = await platformAPI.getCompanyInfo()
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Login error')
        }

        return res.data
    }
)

export const fetchByReviews = createAsyncThunk<ReviewData[], void, { rejectValue: string }>(
    'user/fetchByReviews',
    async (_, { rejectWithValue }) => {
        const res = await platformAPI.getReviews()
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Login error')
        }

        return res.data
    }
)

export const fetchByAddNewReview = createAsyncThunk<string, NewReviewData, { rejectValue: string }>(
    'user/fetchByAddNewReview',
    async (data, { rejectWithValue }) => {
        const res = await platformAPI.addNewReview(data)
        // console.log(res);

        if (res.status !== 201) {
            return rejectWithValue('Something went wrong!')
        }

        return res.data
    }
)

export const fetchByAddNewCallBack = createAsyncThunk<NewCallBackData, NewCallBackData, { rejectValue: string }>(
    'user/fetchByAddNewCallBack',
    async (data, { rejectWithValue }) => {
        const res = await platformAPI.addNewCallBack(data)
        // console.log(res);

        if (res.status !== 201) {
            return rejectWithValue('Something went wrong!')
        }

        return res.data
    }
)

export const fetchByStudentWorks = createAsyncThunk<StudentWorksData[], void, { rejectValue: string }>(
    'user/fetchByStudentWorks',
    async (_, { rejectWithValue }) => {
        const res = await platformAPI.getStudentWorks()
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Something went wrong!')
        }

        return res.data
    }
)

export const fetchByCoopCompanies = createAsyncThunk<CoopCompaniesData[], void, { rejectValue: string }>(
    'user/fetchByCoopCompanies',
    async (_, { rejectWithValue }) => {
        const res = await platformAPI.getCoopCompanies()
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Something went wrong!')
        }

        return res.data
    }
)

export const fetchByCompanyFA = createAsyncThunk<FAQData[], void, { rejectValue: string }>(
    'user/fetchByCompanyFA',
    async (_, { rejectWithValue }) => {
        const res = await platformAPI.getCompanyAQ()
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Something went wrong!')
        }

        return res.data
    }
)

const infoSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {
        toggleFeedbackModal(state, action) {
            state.feedback_modal = action.payload
        },
        toggleSuccessModal(state, action) {
            state.success_feedback = action.payload
        },
        toggleSuccessCallback(state, action) {
            state.success_callback = action.payload
        },
        toggleCitiesModal(state, action) {
            state.cities_modal = action.payload
        }
    },
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchCompanyInfo.pending, (state, action) => {

        })
        addCase(fetchCompanyInfo.fulfilled, (state, action) => {
            state.company = action.payload
        })
        addCase(fetchCompanyInfo.rejected, (state, action) => {
            console.error(action.payload);
        })
        addCase(fetchByReviews.pending, (state, action) => {

        })
        addCase(fetchByReviews.fulfilled, (state, action) => {
            state.reviews = action.payload
        })
        addCase(fetchByReviews.rejected, (state, action) => {
            console.error(action.error);
        })
        addCase(fetchByAddNewReview.pending, (state, action) => {

        })
        addCase(fetchByAddNewReview.fulfilled, (state, action) => {
            state.feedback_modal = false
            state.success_feedback = true
        })
        addCase(fetchByAddNewCallBack.fulfilled, (state, action) => {
            state.success_callback = true
        })
        addCase(fetchByStudentWorks.pending, (state, action) => {

        })
        addCase(fetchByStudentWorks.fulfilled, (state, action) => {
            state.student_works = action.payload
        })
        addCase(fetchByStudentWorks.rejected, (state, action) => {
            console.error(action.payload);
        })
        addCase(fetchByCoopCompanies.fulfilled, (state, action) => {
            state.coop_companies = action.payload
        })
        addCase(fetchByCoopCompanies.rejected, (state, action) => {
            console.error(action.payload);
        })
        addCase(fetchByCompanyFA.fulfilled, (state, action) => {
            state.company_aq = action.payload
        })
        addCase(fetchByCompanyFA.rejected, (state, action) => {
            console.error(action.payload);
        })
    }
})

export const { toggleFeedbackModal, toggleSuccessModal, toggleSuccessCallback, toggleCitiesModal } = infoSlice.actions

export default infoSlice.reducer

