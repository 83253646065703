import React, { FC, FormEventHandler, useState } from 'react'
import s from './Callback.module.css'
import illust from '../../assets/images/HomePage/illustration.png'
import { validateEmail } from '../../reused'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks'
import { fetchByAddNewCallBack } from '../../store/slices/infoSlice'
import SuccessCallback from '../SuccessCallback/SuccessCallback'
import { useTranslation } from 'react-i18next'

const Callback: FC = () => {
    const { error_message, success_callback } = useAppSelector(state => state.info)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [error, setError] = useState('')
    const [feedbackData, setFeddbackData] = useState({
        email: '',
        name: '',
        phone_number: '',
        text: '',
        city: '',
    })

    const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        if (validateEmail(feedbackData.email)) {
            setError(t("auth.email_error"))
        } else if (feedbackData.name.trim().length <= 0) {
            setError(t("home.callback.name_placeholder"))
        } else if (feedbackData.phone_number.trim().length <= 0) {
            setError(t("home.callback.phone_placeholder"))
        } else if (feedbackData.city.trim().length <= 0) {
            setError(t("home.callback.city_placeholder"))
        } else if (feedbackData.text.trim().length <= 0) {
            setError(t("home.callback.text_area_placeholder"))
        } else {
            setError('')
            dispatch(fetchByAddNewCallBack(feedbackData))
            setFeddbackData({
                email: '',
                name: '',
                phone_number: '',
                text: '',
                city: '',
            })
        }
    }

    return (
        <section id='leave_req' className={s.callback_section}>
            <div className={s.dark_bg} />
            <div className={s.container}>
                <div className={s.leftSide}>
                    <img className={s.escalator} src={illust} alt="Illustration" />
                </div>

                <div className={s.rightSide}>
                    <div className={s.textContent}>
                        <h5 className={s.section_title}>
                            {t("home.callback.contact")}
                        </h5>
                        <h2 className={s.title_description}>
                            {t("home.callback.title-description")}
                        </h2>
                        <span className={s.description}>
                            {t("home.callback.description")}
                        </span>
                    </div>

                    <div className={s.actions}>
                        <form onSubmit={handleSubmit} className={s.callback_form}>
                            <span className={`${s.email}, ${s.input_name}`}>
                                {t("home.callback.email")}
                            </span>
                            <input
                                value={feedbackData.email}
                                onChange={(e) => setFeddbackData({ ...feedbackData, email: e.target.value })}
                                className={`${s.email_inp} ${s.callback_input}`} type="email" name="email" id="email" placeholder={t("home.callback.email_placeholder")} />
                            <span className={`${s.name}, ${s.input_name}`}>
                                {t("home.callback.name")}
                            </span>
                            <input
                                value={feedbackData.name}
                                onChange={(e) => setFeddbackData({ ...feedbackData, name: e.target.value })}
                                className={`${s.name_inp} ${s.callback_input}`} type="text" name="name" id="name" placeholder={t("home.callback.name_placeholder")} />
                            <span className={`${s.phone}, ${s.input_name}`}>
                                {t("home.callback.phone")}
                            </span>
                            <input
                                value={feedbackData.phone_number}
                                onChange={(e) => setFeddbackData({ ...feedbackData, phone_number: e.target.value })}
                                className={`${s.phone_inp} ${s.callback_input}`} type="phone" name="phone" id="phone" placeholder={t("home.callback.phone_placeholder")} />

                            <span className={`${s.city}, ${s.input_name}`}>
                                {t("home.callback.city")}
                            </span>
                            <input
                                value={feedbackData.city}
                                onChange={(e) => setFeddbackData({ ...feedbackData, city: e.target.value })}
                                className={`${s.phone_inp} ${s.callback_input}`} type="text" name="city" id="city" placeholder={t("home.callback.city_placeholder")} />

                            <span className={`${s.textarea}, ${s.input_name}`}>
                                {t("home.callback.text-area")}
                            </span>
                            <textarea
                                value={feedbackData.text}
                                onChange={(e) => setFeddbackData({ ...feedbackData, text: e.target.value })}
                                className={`${s.textarea_inp} ${s.callback_input}`} id="textarea" placeholder={t("home.callback.text_area_placeholder")} ></textarea>
                            {error && <span className={s.error}>{error}</span>}
                            {error_message && <span className={s.error}>{t("auth.change_pass_err")}</span>}
                            <button className={s.submit_btn} >
                                {t("home.callback.submit_btn")}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            {success_callback && <SuccessCallback />}
        </section>
    )
}

export default Callback