import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './BurgerMenu.module.css'
import { useAppSelector } from '../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';

interface BurgerMenuProps {
    showSettings: boolean
    setShowSettings: Dispatch<SetStateAction<boolean>>
}

const BurgerMenu: FC<BurgerMenuProps> = ({ setShowSettings, showSettings }) => {
    const { t, i18n } = useTranslation()
    const lang = i18n.language
    const [burgerActive, setBurgerActive] = useState(false)
    const { courses } = useAppSelector(state => state.courses)

    const toggleLang = (lang_name: string) => {
        i18n.changeLanguage(lang_name)
    }

    return (
        <div className={styles.burgerMenu}>
            <div onClick={() => {
                setShowSettings(false)
                setBurgerActive(!burgerActive)
            }}
                className={burgerActive ? styles.menu_btn_active : styles.menu_btn}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className={burgerActive ? styles.menu_active : styles.menu}>
                <nav className={styles.navigation}>
                    <ul>
                        <li className={styles.list}>
                            <NavLink onClick={() => setBurgerActive(!burgerActive)} to={'/'} className={({ isActive }) =>
                                isActive ? styles.activeMenu_item : styles.menu_item
                            }>
                                {lang === 'ru' ? 'Главная' : "Home"}
                            </NavLink>
                        </li>

                        {
                            courses.length > 0 &&
                            courses?.slice(0, 4)?.map(el => (
                                <li className={styles.list} key={el.id}><NavLink
                                    onClick={() => setBurgerActive(!burgerActive)}
                                    className={({ isActive }) => isActive ? styles.activeMenu_item : styles.menu_item}
                                    to={`/courses/${el.id}/${el.name_en}`}>{el.name_en}</NavLink>
                                </li>

                            ))
                        }
                        <li className={styles.list}>
                            <NavLink
                                onClick={() => setBurgerActive(!burgerActive)}
                                className={({ isActive }) => isActive ? styles.activeMenu_item : styles.menu_item}
                                to={'/list_courses'}>{t("header.navigation.link2")}</NavLink>
                        </li>
                        <li><NavLink
                            onClick={() => setBurgerActive(!burgerActive)}
                            className={({ isActive }) => isActive ? styles.activeMenu_item : styles.menu_item}
                            to={'/about_us'}>{t("header.navigation.link")}</NavLink></li>
                        <div className={styles.lang_block}>
                            <span onClick={() => toggleLang('ru')} className={lang === 'ru' ? `${styles.active_lang} ${styles.lang}` : styles.lang}>RU</span>
                            <span onClick={() => toggleLang('en')} className={lang === 'en' ? `${styles.active_lang} ${styles.lang}` : styles.lang}>EN</span>
                        </div>
                    </ul>
                </nav>
            </div >
        </div >
    );
};

export default BurgerMenu;