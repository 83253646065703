import React, { FC, useEffect } from 'react';
import s from './SuccessCallback.module.css'
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store/hooks/hooks';
import { toggleSuccessCallback } from '../../store/slices/infoSlice';

const SuccessCallback: FC = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(toggleSuccessCallback(false))
        }, 3000)

        return () => clearTimeout(timer)
    }, [dispatch])
    return (
        <div className={`${s.wrapper} animate__animated animate__fadeIn animate__faster`}>
            <div className={s.container}>
                <p className={s.thanks}>{t("home.callback.success")}</p>
            </div>
        </div>
    );
};

export default SuccessCallback;