import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { platformAPI } from '../../axios'
import { BookingCoursesData, CourseData, FAQData, MentorsData, NewBookingCourseData, ResumeData, TechnologiesData } from '../modules'

type InfoState = {
    courses: CourseData[]
    course: CourseData | null
    technologie: TechnologiesData | null
    mentors: MentorsData[]
    questions: FAQData[]
    booking: BookingCoursesData[]
    resume: ResumeData | null
}

const initialState: InfoState = {
    courses: [],
    course: null,
    technologie: null,
    mentors: [],
    questions: [],
    booking: [],
    resume: null,
}

export const fetchAllCourses = createAsyncThunk<CourseData[], void, { rejectValue: string }>(
    'courses/fetchAllCourses',
    async (_, { rejectWithValue }) => {
        const res = await platformAPI.getAllCourses()
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Login error')
        }

        return res.data
    }
)

export const fetchCourseById = createAsyncThunk<CourseData, number, { rejectValue: string }>(
    'courses/fetchCourseById',
    async (id, { rejectWithValue }) => {
        const res = await platformAPI.getCourseInfo(id)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Login error')
        }

        return res.data
    }
)

export const fetchResumeByCourse = createAsyncThunk<ResumeData, number, { rejectValue: string }>(
    'courses/fetchResumeByCourse',
    async (id, { rejectWithValue }) => {
        const res = await platformAPI.getResumeByCourse(id)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Login error')
        }

        return res.data[0]
    }
)

export const fetchTechnologieById = createAsyncThunk<TechnologiesData, number, { rejectValue: string }>(
    'courses/fetchTechnologieById',
    async (id, { rejectWithValue }) => {
        const res = await platformAPI.getTechnologie(id)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Login error')
        }

        return res.data
    }
)

export const fetchByMentorsList = createAsyncThunk<MentorsData[], number | string, { rejectValue: string }>(
    'courses/fetchByMentorsList',
    async (id = '', { rejectWithValue }) => {
        const res = await platformAPI.getMentorsList(id)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Login error')
        }

        return res.data
    }
)

export const fetchByFAQ = createAsyncThunk<FAQData[], number, { rejectValue: string }>(
    'courses/fetchByFAQ',
    async (id, { rejectWithValue }) => {
        const res = await platformAPI.getFAQ(id)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Login error')
        }

        return res.data
    }
)

export const fetchByBookingCourses = createAsyncThunk<BookingCoursesData[], { id: number, city: string }, { rejectValue: string }>(
    'courses/fetchByBookingCourses',
    async (data, { rejectWithValue }) => {
        const res = await platformAPI.getBookingCourses(data)
        // console.log(res);

        if (res.status !== 200) {
            return rejectWithValue('Login error')
        }

        return res.data
    }
)

const coursesSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {},
    extraReducers: ({ addCase, addMatcher }) => {
        addCase(fetchAllCourses.pending, (state, action) => {

        })
        addCase(fetchAllCourses.fulfilled, (state, action) => {
            state.courses = action.payload
        })
        addCase(fetchAllCourses.rejected, (state, action) => {
            console.error(action.payload);
        })
        addCase(fetchCourseById.pending, (state, action) => {

        })
        addCase(fetchCourseById.fulfilled, (state, action) => {
            state.course = action.payload
        })
        addCase(fetchCourseById.rejected, (state, action) => {
            console.error(action.payload);
        })
        addCase(fetchTechnologieById.pending, (state, action) => {

        })
        addCase(fetchTechnologieById.fulfilled, (state, action) => {
            state.technologie = action.payload
        })
        addCase(fetchTechnologieById.rejected, (state, action) => {
            console.error(action.payload);
        })
        addCase(fetchByMentorsList.fulfilled, (state, action) => {
            state.mentors = action.payload
        })
        addCase(fetchByMentorsList.rejected, (state, action) => {
            console.error(action.payload);
        })
        addCase(fetchByFAQ.fulfilled, (state, action) => {
            state.questions = action.payload
        })
        addCase(fetchByFAQ.rejected, (state, action) => {
            console.error(action.payload);
        })
        addCase(fetchByBookingCourses.pending, (state, action) => {

        })
        addCase(fetchByBookingCourses.fulfilled, (state, action) => {
            state.booking = action.payload
        })
        addCase(fetchByBookingCourses.rejected, (state, action) => {
            console.error(action.payload);
        })
        addCase(fetchResumeByCourse.fulfilled, (state, action) => {
            state.resume = action.payload
        })
    }
})

export default coursesSlice.reducer

