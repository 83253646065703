import React, { FC, useEffect } from 'react';
import './App.css'
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import SocialLinks from './components/SocialLinks/SocialLinks';
import 'animate.css';
import "animate.css/animate.min.css";
import { useAppDispatch, useAppSelector } from './store/hooks/hooks';
import { getLS } from './LS';
import { fetchUserProfile, setToken } from './store/slices/userSlice';
import Footer from './components/Footer/Footer';


const App: FC = () => {
  const { token } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()

  useEffect(() => {
    let lsToken = getLS('token')
    lsToken = lsToken && JSON.parse(lsToken)

    if (lsToken !== null || lsToken !== undefined) {
      dispatch(setToken(lsToken))
      lsToken && dispatch(fetchUserProfile(lsToken))
    }
  }, [token, dispatch])

  return (
    <div>
      <Header />
      <Main />
      <Footer />
      <SocialLinks />
    </div>
  );
};

export default App;