import React, { FC } from 'react'
import s from './Certificate.module.css'
import { t } from 'i18next'

import { pathLink } from '../../reused'
import { useAppSelector } from '../../store/hooks/hooks'
import { AnimationOnScroll } from 'react-animation-on-scroll'

const Certificate: FC = () => {
    const { company } = useAppSelector(state => state.info)

    return (
        <AnimationOnScroll offset={300} animateOnce={true} animateIn='animate__faster animate__zoomIn'>
            <section className={s.sertificate_section}>
                <div className={s.darker_bg} />
                <div className={s.container}>
                    <div className={s.leftSide}>
                        <h5 className={s.section_title}>
                            {t("home.certificate.certificate")}
                        </h5>
                        <h2 className={s.title_description}>
                            {t("home.certificate.title-description")}
                        </h2>
                        <p className={s.description}>
                            {t("home.certificate.description")}
                        </p>
                    </div>

                    <div className={s.rightSide}>
                        <img className={s.certificate_sample_img} src={pathLink + company?.certificate_image} alt="certificate" />
                    </div>
                </div>
            </section>
        </AnimationOnScroll>
    )
}

export default Certificate