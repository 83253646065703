import React, { FC } from 'react'
import s from './SkillsYouGain.module.css'
import { CourseData } from '../../../../store/modules'
import TechnologiesCard from './components/TechnologiesCard'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

interface SkillsYouGainProps {
    course: CourseData
}

const SkillsYouGain: FC<SkillsYouGainProps> = ({ course }) => {

    const { technologies, name_en, name_ru, id } = course
    const { i18n } = useTranslation()
    const lang = i18n.language

    return (
        <div className={s.skills_section}>
            <div className={s.container}>
                <h2 className={s.title}>
                    {
                        t("course.skillsYouGain.WhatWillYouLearnDuringTheCourse")
                    }
                </h2>
                <p className={s.description}>
                    {
                        `${t("course.skillsYouGain.relatedTechnologies")} ${lang === 'ru' ? name_ru : name_en} ${t("course.skillsYouGain.developer")}`
                    }
                </p>
                <div className={s.technologies}>
                    {technologies.length > 0 &&
                        technologies.map(technology => (
                            <TechnologiesCard
                                course_id={id}
                                key={technology.id}
                                technology={technology} />
                        ))}
                </div>
            </div>
        </div>
    )
}

export default SkillsYouGain