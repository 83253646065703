import React, { FC } from 'react';
import s from './HelloProfile.module.css'
import { useAppSelector } from '../../../store/hooks/hooks';
import { pathLink } from '../../../reused';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const HelloProfile: FC = () => {
    const { avatar, first_name, } = useAppSelector(state => state.user.user_profile)
    const { t } = useTranslation()

    return (
        <div className={s.container}>
            <div className={s.profile_block}>
                <img className={s.avatar_img} src={avatar?.startsWith('http') ? `https${avatar.slice(4)}` : pathLink + avatar} alt="avatar" />
                <div className={s.text_block}>
                    <h1>{t("my_courses.hello_profile.hello")} {first_name}!</h1>
                    <p>{t("my_courses.hello_profile.productive")}</p>
                </div>
            </div>
            <Link to={'/list_courses'}>
                <button className={s.add_btn}>{t("my_courses.hello_profile.add_btn")}</button>
            </Link>
        </div>
    );
};

export default HelloProfile;