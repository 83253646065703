import React, { FC, useEffect } from 'react';
import s from './Technologie.module.css'
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchTechnologieById } from '../../store/slices/coursesSlice';
import Portfolio from '../../components/Portfolio/Portfolio';
import Mentors from '../../components/Mentors/Mentors';
import Callback from '../../components/Callback/Callback';
import TechnologyHero from './TechnologyHero/TechnologyHero';
import Description from './Description/Description';
import { Helmet } from 'react-helmet-async';

const Technologie: FC = () => {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const { technologie } = useAppSelector(state => state.courses)
    const [searchParams] = useSearchParams()
    const course_id = searchParams.get('course_id')

    // console.log(technologie);


    useEffect(() => {
        dispatch(fetchTechnologieById(Number(id)))
        window.scrollTo(0, 0)
    }, [id, dispatch])

    return (
        <div>
            <Helmet>
                <link rel="canonical" href={`https://itcbootcamp.com/courses/${id}/${technologie?.name_en}`} />
                <title>
                    {`${technologie?.name_en} | ITCbootcamp`}
                </title>
            </Helmet>
            {technologie && <TechnologyHero technologie={technologie} />}
            {
                technologie &&
                <Description technologie={technologie} />
            }
            <Portfolio />
            <Mentors id={Number(course_id)} />
            <Callback />
        </div>
    );
};

export default Technologie;