import React, { FC, useEffect, useState } from 'react';
import s from './Thanks.module.css'
import { Helmet } from 'react-helmet-async';
import { useAppSelector } from '../../store/hooks/hooks';
import { platformAPI } from '../../axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import thanks_img from '../../assets/images/thanks_img.png'

const Thanks: FC = () => {
    const { token } = useAppSelector(state => state.user)
    const [isLoading, setIsLoading] = useState(false)
    const [searchParams] = useSearchParams()
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        setIsLoading(true)
        try {
            let payment = JSON.parse(localStorage.getItem('payment') || '')
            if (payment?.pay_name === 'booking' && payment.payment_id === searchParams.get('pg_payment_id') && payment !== null) {
                platformAPI.addNewBookingCourse({ token, id: payment?.booking_course_id, city: payment?.city })
                    .then(res => {
                        localStorage.removeItem('payment')
                        setTimeout(() => {
                            navigate('/my_courses', { replace: true })
                        }, 5000)
                    })
                    .catch(err => setError('Oops something went wrong!'))
                    .finally(() => setIsLoading(false))
            } else if (payment?.pay_name === 'payTech' && payment?.payment_id === searchParams.get('pg_payment_id') && payment !== null) {
                platformAPI.addNewTechnologyByUser({ token, id_technologie: payment?.booking_course_id })
                    .then(res => {
                        localStorage.removeItem('payment')
                        setTimeout(() => {
                            navigate('/my_courses', { replace: true })
                        }, 5000)
                    })
                    .catch(err => setError('Oops something went wrong!'))
                    .finally(() => setIsLoading(false))
            } else if (payment?.pay_name === 'payFull' && payment?.payment_id === searchParams.get('pg_payment_id') && payment !== null) {
                platformAPI.addNewFullCourseByUser({ token, id: payment?.booking_course_id })
                    .then(res => {
                        localStorage.removeItem('payment')
                        setTimeout(() => {
                            navigate('/my_courses', { replace: true })
                        }, 5000)
                    })
                    .catch(err => setError('Oops something went wrong!'))
                    .finally(() => setIsLoading(false))
            }

        } catch (error) {
            setIsLoading(false)
            setError('Oops something went wrong!')
            setTimeout(() => {
                navigate('/')
            }, 5000)
        }

    }, [navigate])

    if (isLoading) {
        return <h1 className={s.loading}>Проверка...</h1>
    }

    if (error) {
        return <h1 className={s.error}>{error}</h1>
    }

    return (
        <section className={s.thanks_wrapper}>
            <Helmet>
                <title>Thanks!!! - ITCbootcamp.com</title>
            </Helmet>
            <div className={s.container}>
                <div className={s.text_block}>
                    <h1 className={s.title}>{t("thanks.title")}</h1>
                    <p className={s.desc}>{t("thanks.desc")}</p>
                </div>
                <div className={s.img_block}>
                    <img src={thanks_img} alt="thanks" />
                </div>
            </div>

        </section>
    );
};

export default Thanks;