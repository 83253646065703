import React, { FC } from 'react'
import s from './Technology.module.css'
import { useTranslation } from 'react-i18next'
import { TechnologiesData } from '../../../store/modules'
import { Link } from 'react-router-dom'

interface TechnologyHeroProps {
    technologie: TechnologiesData
}

const TechnologyHero: FC<TechnologyHeroProps> = ({ technologie }) => {
    const { name_en, name_ru, logo } = technologie
    const { t, i18n } = useTranslation()
    const lang = i18n.language

    return (
        <section className={s.hero_section}>
            <div className={s.dark_bg} />

            <div className={s.container}>
                <div className={`${s.leftSide} ${s.block}`} >

                    <h1 className={s.main_title}>{`${lang === 'ru' ? name_ru : name_en} `}</h1>
                    <p className={s.title_description}>{t("home.main.title-description")}</p>
                    <div className={s.main_section_buttons}>
                        <Link to={'/list_courses'} className={s.whatchAPresentation}>
                            {t("course.hero.purchaseCourse")}
                        </Link>
                    </div>
                </div>

                <div className={`${s.rightSide} ${s.block}`}>
                    <img className={s.technologyIcon} src={`https${logo.slice(4)}`} alt={name_en} />
                </div>
            </div>

        </section >
    )
}

export default TechnologyHero