import React, { FC, useEffect } from 'react';
import Description from './Description/Description';
import Feedback from '../../components/Feedbacks/Feedback';
import AllMentors from './AllMentors/AllMentors';
import Callback from '../../components/Callback/Callback';
import Hero from './Hero/Hero';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const AboutUs: FC = () => {
    const { i18n } = useTranslation()
    const lang = i18n.language

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://itcbootcamp.com/about_us" />
                <title>
                    {lang === 'ru' ? `О компании | ITCbootcamp` : `About company | ITCbootcamp`}
                </title>
            </Helmet>
            <Hero />
            <Description />
            <Feedback />
            <AllMentors />
            <Callback />
        </div>
    );
};

export default AboutUs;