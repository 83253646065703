import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import s from './NewFeedbackModal.module.css'
import closeIcon from '../../assets/images/close.png'
import { useTranslation } from 'react-i18next';
import { fetchByAddNewReview, toggleFeedbackModal } from '../../store/slices/infoSlice';

const NewFeedbackModal: FC = () => {
    const { feedback_modal } = useAppSelector(state => state.info)
    const { token } = useAppSelector(state => state.user)
    const [reviewText, setReviewText] = useState('')
    const [error, setError] = useState('')
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const addNewReview = () => {
        if (reviewText.trim().length > 0 && token) {
            setError('')
            dispatch(fetchByAddNewReview({ token, comment: reviewText }))
        } else {
            setError(t("home.feedbacks.error"))
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                dispatch(toggleFeedbackModal(false))
            }
        })
    }, [dispatch])

    useEffect(() => {
        if (feedback_modal) {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = (e) => {
                dispatch(toggleFeedbackModal(false));
            };
        }
        return () => {
            if (!feedback_modal) window.history.back();
            window.onpopstate = () => { };
        };

    }, [feedback_modal, dispatch])
    return (
        <div className={`${s.wrapper} animate__animated animate__fadeIn animate__faster`} onClick={() => dispatch(toggleFeedbackModal(false))}>
            <div className={s.container} onClick={(e) => e.stopPropagation()}>
                <div className={s.title_block}>
                    <h5 className={s.title}>{t("home.feedbacks.modal_title")}</h5>
                    <img onClick={() => dispatch(toggleFeedbackModal(false))} className={s.close} src={closeIcon} alt="close" />
                </div>
                <div>
                    <h2 className={s.under_title}>{t("home.feedbacks.under_title")}</h2>
                    <p className={s.under_title_text}>{t("home.feedbacks.under_title_text")}</p>
                </div>
                <div>
                    <textarea
                        value={reviewText}
                        onChange={(e) => setReviewText(e.target.value)}
                        placeholder={t("home.feedbacks.placeholder")} className={s.area}></textarea>
                </div>
                <button onClick={addNewReview} className={s.save_btn}>{t("home.feedbacks.btn")}</button>
                {error && <span className={s.error}>{error}</span>}
            </div>
        </div>
    );
};

export default NewFeedbackModal;