import React, { FC, useEffect } from 'react';
import s from './Whishlist.module.css'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByGetListFavorites } from '../../store/slices/userCoursesSlice';
import { useTranslation } from 'react-i18next';
import goBack from '../../assets/images/goBack.png'
import { Link, useNavigate } from 'react-router-dom';
import { pathLink } from '../../reused';
import { Helmet } from 'react-helmet-async';

const Whishlist: FC = () => {
    const { t, i18n } = useTranslation()
    const lang = i18n.language
    const dispatch = useAppDispatch()
    const { token } = useAppSelector(state => state.user)
    const { whishlist } = useAppSelector(state => state.list_courses)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(fetchByGetListFavorites(token))
    }, [dispatch])
    return (
        <div className={s.container}>
            <Helmet>
                <title>
                    {lang === 'ru' ? `Избранные видео | ITCbootcamp` : `Featured Videos | ITCbootcamp`}
                </title>
            </Helmet>
            <div className={s.title_block}>
                <img onClick={() => navigate('/my_courses')} src={goBack} alt="back" />
                <h1 className={s.title_top}>{t("video.whishlist_title")}</h1>
            </div>
            <div className={s.wrapper}>
                {
                    whishlist.length > 0 ?
                        whishlist.map(el => (
                            <div key={el.id} className={s.card}>
                                <Link to={`/videos-from-whishlist/${el?.video}/`}>
                                    <img className={s.preview} src={pathLink + el?.preview} alt="preview" />
                                </Link>
                                <h3
                                    title={lang === 'ru' ? el?.name_ru : el?.name_en}
                                    className={s.title}>
                                    {lang === 'ru' ?
                                        el?.name_ru.length > 55 ? el?.name_ru.slice(0, 55) + '...' : el?.name_ru
                                        : el?.name_en.length > 55 ? el?.name_en.slice(0, 55) + '...' : el?.name_en}
                                </h3>
                            </div>
                        ))
                        :
                        <h2 className={s.empty}>{t("video.empty_whishlist")}</h2>
                }
            </div>
        </div>
    );
};

export default Whishlist;