
export const validateEmail = (email: string) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
        return false;
    } else {
        return true;
    }
}

export const pathLink = 'https://itcbootcamp.com:4443'

export const videoLink = 'https://itcbootcamp.com:4443/api/cources/stream/'