import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import s from './Advantages.module.css'
import worldIcon from '../../../../assets/images/HomePage/world.png'
import laptopIcon from '../../../../assets/images/HomePage/computers-devices.png'
import cloudIcon from '../../../../assets/images/HomePage/cloud-storage.png'
import diagIcon from '../../../../assets/images/HomePage/business-products.png'

const Advantages: FC = () => {
    const { t } = useTranslation()

    return (
        <section className={s.advantages_section}>
            <div className={s.gradient} />
            <div className={s.container}>
                <h2 className={s.section_title}>{t("home.advantages.section-title")}</h2>
                <p className={s.section_title_des}>{t("home.advantages.uniquenessOfThecourses")}</p>

                <div className={s.advantages_list}>
                    <div className={s.advantages_card}>
                        <img src={worldIcon} alt="World Wide" />
                        <span>{t("home.advantages.uniqueness1")}</span>
                    </div>
                    <div className={s.advantages_card}>
                        <img src={laptopIcon} alt="World Wide" />
                        <span>{t("home.advantages.uniqueness2")}</span>
                    </div>
                    <div className={s.advantages_card}>
                        <img src={cloudIcon} alt="World Wide" />
                        <span>{t("home.advantages.uniqueness3")}</span>
                    </div>
                    <div className={s.advantages_card}>
                        <img src={diagIcon} alt="World Wide" />
                        <span>{t("home.advantages.uniqueness4")}</span>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default Advantages