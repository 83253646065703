import React, { FC } from 'react'
import s from './Hero.module.css'
import playIcon from '../../../../assets/images/HomePage/play_icon.png'
import { t } from 'i18next'
import { CourseData } from '../../../../store/modules'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface HeroProps {
    course: CourseData;
}


const Hero: FC<HeroProps> = ({ course }) => {

    const { name_en, name_ru, id, logo } = course

    const { i18n } = useTranslation()
    const lang = i18n.language

    return (
        <section className={s.hero_section}>
            <div className={s.dark_bg} />

            <div className={s.container}>
                <div className={`${s.leftSide} ${s.block}`} >

                    <h1 className={s.main_title}>{`${lang === 'ru' ? name_ru : name_en} ${t("course.hero.developer")} `}</h1>
                    <p className={s.title_description}>{t("home.main.title-description")}</p>
                    <div className={s.main_section_buttons}>
                        <Link className={s.link_booking} to={`/booking_course/${id}`}>
                            <button className={s.leaveAReq_btn}>{t("course.hero.bookAPlaceBtn")}</button>
                        </Link>
                        <Link to={'/list_courses'} className={s.whatchAPresentation}>
                            <img className={s.playIcon} src={playIcon} alt="play" />
                            {t("course.hero.purchaseCourse")}
                        </Link>
                    </div>
                </div>

                <div className={`${s.rightSide} ${s.block}`}>
                    <img src={`https${logo.slice(4)}`} alt="Programmer" />
                </div>
            </div>

        </section >
    )
}

export default Hero