import React, { FC } from 'react';
import s from './ListVideosFavor.module.css'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store/hooks/hooks';
import { Link } from 'react-router-dom';

interface ListVideosProps {
    video_id: string
}

const ListVideosFavor: FC<ListVideosProps> = ({ video_id }) => {
    const { t, i18n } = useTranslation()
    const { whishlist } = useAppSelector(state => state.list_courses)
    const lang = i18n.language

    return (
        <div className={s.list_wrapper}>
            <h3 className={s.list_title}>
                {t("video.whishlist_title")}
            </h3>
            <div className={s.wrapper_links}>
                {
                    whishlist.length > 0 &&
                    whishlist.map(el => (
                        <Link className={Number(video_id) === el.video ? `${s.active_video} ${s.video_link}` : s.video_link}
                            to={`/videos-from-whishlist/${el.video}`}
                            key={el.id}>
                            <h4>{lang === 'ru' ? el.name_ru : el.name_en}</h4>
                        </Link>
                    ))
                }
            </div>
        </div>
    );
};

export default ListVideosFavor;