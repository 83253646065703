import React, { FC } from 'react'
import s from './TechnologyCallback.module.css'
import Callback from '../../../../components/Callback/Callback'
import { useTranslation } from 'react-i18next'


const TechnologyCallback: FC = () => {
    const { t } = useTranslation()

    return (
        <div>
            <div className={s.technology_callback}>
                <div className={s.dark_bg} />
                <div className={s.container}>
                    <h5 className={s.title}>{t("technology_info.title")}</h5>
                    <h2 className={s.title_description}>{t("technology_info.under_title")}</h2>
                    <p className={s.description}>{t("technology_info.description")}</p>
                </div>
            </div>
            <Callback />
        </div>
    )
}

export default TechnologyCallback