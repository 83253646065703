export const setLS = (key: string, item: string) => {
    localStorage.setItem(key, JSON.stringify(item))
}

export const getLS = (key: string) => {
    return localStorage.getItem(key)
}

export const removeLS = (key: string) => {
    localStorage.removeItem(key)
}