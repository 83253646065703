import React, { FC } from 'react'
import s from './StudyingProcess.module.css'
import connectLine from '../../../../assets/images/HomePage/connect line.png'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { useTranslation } from 'react-i18next'


const StudyingProcess: FC = () => {
    const { t } = useTranslation()
    return (
        <AnimationOnScroll offset={300} animateOnce={true} animateIn='animate__faster animate__zoomIn'>
            <section className={s.stydyingProcess_section}>
                <div className={s.dark_bg} />
                <div className={s.container}>
                    <h2 className={s.title_des}>
                        {t("home.education_process-section.education_process")}
                    </h2>
                    <p className={s.description}>
                        {t("home.education_process-section.education_process-description")}
                    </p>

                    <div className={s.education_processes}>
                        <div className={s.line_block1}><img id={s.line1} className={s.connectLine} src={connectLine} alt="connectLine" /></div>
                        <div className={s.line_block2}><img id={s.line2} className={s.connectLine} src={connectLine} alt="connectLine" /></div>
                        <div className={s.process}>
                            <div className={`${s.first_process} ${s.num}`}>1</div>
                            <p>
                                {t("home.education_process-section.process1")}
                            </p>
                            <span>
                                {t("home.education_process-section.process1_des")}
                            </span>
                        </div>
                        <div className={s.process}>
                            <div className={`${s.second_process} ${s.num}`}>2</div>
                            <p>
                                {t("home.education_process-section.process2")}
                            </p>
                            <span>
                                {t("home.education_process-section.process2_des")}
                            </span>
                        </div>
                        <div className={s.process}>
                            <div className={`${s.third_process} ${s.num}`}>3</div>
                            <p>
                                {t("home.education_process-section.process3")}
                            </p>
                            <span>
                                {t("home.education_process-section.process3_des")}
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </AnimationOnScroll>
    )
}

export default StudyingProcess