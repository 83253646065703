import React, { FC } from 'react'
import s from './CourseItem.module.css'
import Technologies from './Technologies';
import { useTranslation } from 'react-i18next';
import { CourseData } from '../../../store/modules';
import { Link } from 'react-router-dom';
import { platformAPI } from '../../../axios';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { toggleRegist } from '../../../store/slices/userSlice';

interface CourseItemProps {
    course: CourseData
}

const CourseItem: FC<CourseItemProps> = ({ course }) => {
    const { name_en, name_ru, logo, technologies, id, price } = course;
    const { t, i18n } = useTranslation()
    const { token } = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()
    const lang = i18n.language

    const addNewFullCourse = () => {
        if (token) {
            platformAPI.getPyamentLinkByTechnology({
                currency: 'KGS',
                amount: `${price}`,
                description: name_ru,
            })
                .then(res => {
                    // console.log(res.data)
                    const data = {
                        order_id: res.data.order_id,
                        pay_name: 'payFull',
                        booking_course_id: id,
                        payment_id: res.data.payment_id,
                    }
                    localStorage.setItem('payment', JSON.stringify(data))
                    window.location.href = res.data.url
                })
                .catch(err => console.log(err))
        } else {
            dispatch(toggleRegist(true))
        }
    }

    return (
        <section className={s.course_section}>
            <div className={s.container}>
                <h2 className={s.directionName}>
                    {lang === 'ru' ? name_ru : name_en}
                </h2>
                <div className={s.course_card}>
                    <div className={s.course_direction}>
                        <div className={s.icon_box}>
                            <img className={s.icon} src={`https${logo.slice(4)}`} alt={lang === 'ru' ? name_ru : name_en} />
                        </div>
                        <div className={s.course_dir_detail}>
                            <h3>
                                {lang === 'ru' ? name_ru : name_en}
                            </h3>

                            <p className={s.clue}>{t("allCourses.clue")}</p>
                            <Link to={`/courses/${id}/${name_en}`}>
                                <button className={s.detailBtn}>
                                    {t("allCourses.detailBtn")}
                                </button>
                            </Link>
                        </div>
                        <div className={s.course_dir_actions}>
                            <Link className={s.link_booking} to={`/booking_course/${id}`}>
                                <button className={s.bookCourse}>
                                    {t("allCourses.bookAPlaceBtn")}
                                </button>
                            </Link>
                            {
                                price &&
                                <button onClick={addNewFullCourse} className={s.purchaseCourse}>
                                    {t("allCourses.purchaseCourse")} {price} KGS
                                </button>
                            }
                            {/* <p className={s.price_block}>
                                {t("booking.price")}
                                <span className={s.price}>{price} KGS</span>
                            </p> */}
                        </div>

                    </div>

                    {
                        technologies.length > 0 && price &&
                        technologies?.map((technology) => (
                            <Technologies
                                key={technology.id}
                                technology={technology}
                                course_id={Number(id)}
                            />
                        ))
                    }

                </div>
            </div>
        </section>
    )
}

export default CourseItem