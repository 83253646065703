import React, { FC, useEffect } from 'react';
import s from './AllCourses.module.css'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import CourseItem from './CourseItem/CourseItem';
import FAQ from './FAQ/FAQ';
import Callback from '../../components/Callback/Callback';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { fetchByUserCourses } from '../../store/slices/userCoursesSlice';


const AllCourses: FC = () => {
    const { t, i18n } = useTranslation()
    const { courses } = useAppSelector(state => state.courses)
    const { token } = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()
    const lang = i18n.language

    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.removeItem('payment')
    }, [])

    useEffect(() => {
        if (token) {
            dispatch(fetchByUserCourses(token))
        }
    })

    // console.log("all", courses);


    return (
        <div className={s.allCourses}>
            <Helmet>
                <link rel="canonical" href="https://itcbootcamp.com/list_courses" />
                <title>
                    {lang === 'ru' ? `Список курсов | ITCbootcamp` : `List courses | ITCbootcamp`}
                </title>
            </Helmet>
            <div>
                <div className={s.container}>
                    <h2 className={s.allCourses_title}>
                        {t("allCourses.allCourses")}
                    </h2>
                </div>
                {
                    courses.length > 0 &&
                    courses?.map(course => (
                        <CourseItem
                            key={course.id}
                            course={course}
                        />
                    ))
                }
                <FAQ />
                <Callback />

            </div>
        </div>
    );
};

export default AllCourses;