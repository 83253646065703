import React, { FC } from 'react';
import s from './List.module.css'
import { useAppSelector } from '../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { pathLink } from '../../../reused';
import { Link } from 'react-router-dom';

const List: FC = () => {
    const { t, i18n } = useTranslation()
    const lang = i18n.language
    const { list } = useAppSelector(state => state.list_courses)

    return (
        <div className={s.users_course_list}>
            {
                list.length > 0 ?
                    list.map(el => (
                        <div className={s.course_card} key={el.id}>
                            <div className={s.inset_card}>
                                <div className={s.logo_block}>
                                    <img src={pathLink + el.technology_logo} alt={el.technology_name_en} />
                                </div>
                                <h3 className={s.title}>{lang === 'ru' ? el.technology_name_ru : el.technology_name_en}</h3>
                                <Link to={`/my_courses/list-by-technologie/${el.id}`}
                                    className={s.btn_learn}>
                                    {t("user_courses.btn_learn")}
                                </Link>
                            </div>
                        </div>
                    ))
                    :
                    <h2 className={s.none_title}>{t("user_courses.none_courses")}</h2>
            }
        </div>
    );
};

export default List;