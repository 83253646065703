import React, { FC } from 'react'
import s from './Payment.module.css'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../store/hooks/hooks'
import { Helmet } from 'react-helmet-async'


const Payment: FC = () => {
    const { company } = useAppSelector(state => state.info)
    const { t, i18n } = useTranslation()
    const lang = i18n.language

    return (
        <div className={`${s.payment_page} animate__animated animate__fadeIn animate__faster`}>
            <Helmet>
                <title>
                    {lang === 'ru' ? 'Публичный договор | ITCbootcamp' : 'Public contract | ITCbootcamp'}
                </title>
            </Helmet>
            <div className={s.container}>
                <h2 className={s.payment_title}>
                    {t("payment.payment")}
                </h2>

                <p className={s.text}>{lang === 'ru' ? company?.offerta_ru : company?.offerta_en}</p>
            </div>
        </div>
    )
}

export default Payment